/* Styles for wrapping the search box */

.marketPlace_body {
    background-color: #f7f2f7;
}

.main {
    width: 80vw;
    margin: 50px auto;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.searchBar_btn {
    padding-top: 1rem;
}

.market_btn {
    margin-bottom: 0.5rem;
    background-color: #191f52;
    border-radius: 100px;
}

.cardBody_main {
    height: 70vh;
    overflow: scroll;

}

.ratingBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.marketRating {
    display: flex;
    flex-direction: column;
}

.sspanRating {
    font-weight: bold;
}

/* Cardbody CSS */

.card-info {
    display: inline-block;
    margin-left: -10px;
}

.card-info p {
    font-size: 15px;
    margin-bottom: 0px;
}

.featured-curriculums .card-info {
    margin-left: 0px;
}

.faculties .card-info {
    padding-top: 70px;
}

#booking .card-info {
    display: block;
}


.card-bg {
    background-color: #fff;
    border: 2px solid #ececec;
    border-radius: 15px;
    box-shadow: 0px 0px 5px #00000033;
}

.card-block {
    font-size: 15px;
    position: relative;
    margin: 0;
    padding: 1em;
    border: none;
    box-shadow: none;
}

.profile_pic {
    position: relative;
    bottom: 60px;
    margin-bottom: -50px;
}


.profile-avatar {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #bbb;
}

.profile-inline {
    position: relative;
    top: 0;
    display: inline-block;
}

.profile {
    overflow: hidden;
    box-sizing: border-box;
    width: 45px;
    height: 45px;
    margin: 0;
    border: 1px solid #fff;
    border-radius: 50%;
}

.profile-inline~.card-title {
    display: inline-block;
    margin-left: 4px;
    vertical-align: top;
    margin-bottom: 0px;
}

.card-title {
    font-size: 16px;
    font-weight: 600;
}

.btn-link .card-title {
    letter-spacing: 0px;
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    padding-top: 10px;
}

.sub-title {
    /* font-weight: 300; */
    line-height: 25px;
    font-weight: 500;
}

.card-text {
    text-align: left;
    color: #999999;
    font-family: 'Open Sans', sans-serif;
}

.cardDescriptio{
    height: 6rem;
    align-items: center;
}

.card-rate small {
    color: #555;
    font-weight: 500;
    font-size: 15px;

}

.card-rate {
    color: #813985;
    font-size: 20px !important;
    font-weight: 700;
}

.card-rate small {
    margin-right: 15px;
    font-weight: 600;
}

.btn-cart i {
    padding-right: 8px;
}

.btn-cart {
    background-color: #e5a300;
    padding: 5px 20px 5px 20px;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
}

a.btn-cart.divcentr {
    margin: 0 auto;
    display: table;
    text-align: center;
    width: auto;
    margin-top: 20px;
}

.btn-cart:hover {
    color: #fff;
    text-decoration: none;
}

.container-fluid {
    max-width: 80%
}

.card_course_img {
    width: 100%;
    height: 100%;
}

.card_banner {
    height: 550px;
    width: 90vw;
    margin: auto;
    padding-left: 70px;
    padding-bottom: 50px;
    display: flex;
    background-color: #2b316b;
}

.card_bannerText {
    color: white;
}

.banner_headText {
    font-weight: bold;
}

.card_left_div {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 4rem;
}

.card_right_div {
    width: 40%;
}

.card_banner_searchBar {
    width: 80%;
}

.card_bannerBtn:hover {
    background-color: #1a8ca3;
    color: black;
}

.card_bannerBtn {
    background-color: #F77E0B;
    border-radius: 0.5rem;
    color: white;
    font-weight: bold;
    margin: 0;
}

.card_searchInput {
    border-radius: 0.5rem;
    border: 1px solid #ced4da;
}

.card_searchInputField {
    border: none;
}

.market_btn:hover {
    color: black;
    background-color: white;
}

.card_banner_img {
    height: 300px;
    width: 450px;
}

.banner_img {
    padding-top: 7rem;
}

.course_rating{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.rating_star{
    align-items: center;
    padding-left: 10px;
}

.card_SearchInputBtn{
    display: flex;
}

.noDataText{
    padding-bottom: 150px;
}

.bannerTxt{
    color: #F77E0B;
}

.cardGrade{
    padding-top: 0.2rem;
    font-weight: 500;
}

@media screen and (max-width: 1024px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 10px;
    }

    .btn-lern {
        font-size: 13px;
    }

    .btn-cart {
        font-size: 13px;
    }

    .card-title {
        font-size: 15px;
    }

    .faculties .btn-lern {
        padding: 5px 10px;
    }
}

@media screen and (max-width: 1024px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 10px;
    }

    .btn-lern {
        font-size: 13px;
    }

    .btn-cart {
        font-size: 13px;
    }

    .card-title {
        font-size: 15px;
    }

    .faculties .btn-lern {
        padding: 5px 10px;
    }
}

@media screen and (max-width: 1024px) {
    .card_banner {
        flex-direction: column-reverse;
        height: fit-content;
    }

    .card_left_div {
        width: 100%;
        padding-top: 30px;
        padding-right: 20px;
        padding-left: 2rem;
    }

    .card_right_div{
        height: 100%;
        width: 100%;
    }

    .banner_img {
        height: 100%;
        width: 100%;
        padding-top: 2rem;
    }

    .card_banner_img {
        height: 300px;
        width: 90%;
    }

    .searchBar_btn{
        display: flex;
        flex-direction: column;
    }

    .course_rating{
        flex-direction: row;
    }

    .card_banner{
        padding: 0;
        width: 100%;
    }

    .card_SearchInputBtn{
        width: 325px;
    }

    .main{
        padding: 0;
    }

    .cardDescriptio{
        height: 10rem;
    }
    
}

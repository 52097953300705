.font-bold-600{
    font-weight: 600 !important;
}
/*.heading-wrapper p, .content-title p, .teacher-title p, .heading p {
    text-align: left;
}*/

a.for-btn-width-100 {
    color: #040404;
    font-size: 24px;
    font-family: 'Montserrat';
    width: 100%;
    border: 1px solid #040404;
    display: inline-block;
    line-height: 66px;
    text-decoration: unset;
    border-radius: 10px;
    text-align: center;
}

.slick-slider-sign.slick-slide.slick-current.slick-active {
    border-right: none !important;
}

.oversion-content {
	width: 100% !important;
	padding: 10px 10px;
}

.scrol-icon {
    cursor: pointer;
    color: white;
}

.slide img {    
    max-width: 100%;
 }

@media only screen and (min-device-width: 320px) and (max-device-width: 375px){
    .slide img {
        background-repeat: no-repeat, repeat;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        background-position: bottom;
        background-size: cover;   
     } 
    
}

@media only screen and (min-device-width: 376px) and (max-device-width: 414px){	
	#mobileclick ul.nav-menu {
	    padding: 0;
	    position: absolute;
	    background: #fff;
	    z-index: 999;
	    right: 0;
	    width: 250px;
	}
	#mobileclick li.nav-item {
	    list-style: none;
	    text-align: left;
	    display: block;
	    padding: 0px 5px !important;
	}
	.whatsapp-desktop {
		display: none !important;
	}
	.whatsapp-mobile {
		display: block !important;
	}
}

@media only screen and (min-device-width: 415px) and (max-device-width: 764px){    
	#mobileclick ul.nav-menu {
	    padding: 0;
	    position: absolute;
	    background: #fff;
	    z-index: 999;
	    right: 0;
	    width: 250px;
	}
	#mobileclick li.nav-item {
	    list-style: none;
	    text-align: left;
	    display: block;
	    padding: 0px 5px !important;
	}	
	.whatsapp-desktop {
		display: none !important;
	}
	.whatsapp-mobile {
		display: block !important;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 375px){    
	#mobileclick ul.nav-menu {
	    padding: 0;
	    position: absolute;
	    background: #fff;
	    z-index: 999;
	    right: 0;
	    width: 250px;
	}
	#mobileclick li.nav-item {
	    list-style: none;
	    text-align: left;
	    display: block;
	    padding: 0px 5px !important;
	}	
	.whatsapp-desktop {
		display: none !important;
	}
	.whatsapp-mobile {
		display: block !important;
	}
}

@media only screen and (min-device-width: 240px) and (max-device-width: 319px){
    #mobileclick ul.nav-menu {
	    padding: 0;
	    position: absolute;
	    background: #fff;
	    z-index: 999;
	    right: 0;
	    width: 250px;
	}
	#mobileclick li.nav-item {
	    list-style: none;
	    text-align: left;
	    display: block;
	    padding: 0px 5px !important;
	}
	.whatsapp-desktop {
		display: none !important;
	}
	.whatsapp-mobile {
		display: block !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.whatsapp-desktop {
		display: none;
	}
	.whatsapp-mobile {
		display: block !important;
	}
}

button.slick-prev.slick-arrow:before {
    border: none !important;    
    padding: 0px 0px !important;
    font-size: 20px !important;    
}
button.slick-next.slick-arrow:before {   
	border: none !important;    
    padding: 0px 0px !important;
    font-size: 20px !important;    
}
.sign-up button.slick-next.slick-arrow:before {
    border: none !important;
}
.sign-up button.slick-prev.slick-arrow:before {
	border: none !important;
}
#why-teacher .slick-arrow:before {
	border: none !important;
}
#why-teacher button.slick-next {
	border: none !important;
}
@media only screen and (min-device-width: 376px) and (max-device-width: 414px){
	button.slick-prev.slick-arrow {
	    left: -15px !important;
	}
}

.accordion .card-header:before {
	cursor: pointer;
}

.accordion .card-header.collapsed:before {
	cursor: pointer;
}

/*
http://formoid.com/articles/bootstrap-media-queries-css-1667.html
*/
@media (min-width: 1200px) {
	.container-banner {
		max-width: 1400px;
	}

	.heading-wrapper p {
		font-size: 20px !important;
	}
	.make-img img {
		width: 96%;
	}
	.slider-top-space {
		top: 60px !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) 
{
	.container-banner {
		max-width: 1250px;
	}

	.heading-wrapper p {
		font-size: 20px !important;
	}
	.make-img img {
		width: 96%;
	}
	.slider-top-space {
		top: 60px !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.container-banner  {
		max-width: 960px;
	}
	.make-img img {
		width: 96%;
	}
}

@media (min-width: 576px) and (max-width: 767px){
	.container-banner  {
		max-width: 720px;
	}
}

@media (max-width: 575px) {
	.container-banner  {
		max-width: 540px;
	}
}

.hader .logo-img img {
    width: auto !important
}

.foter-top .logo-img img {
	width: auto !important
}

.content-wrapper {
	padding: 50px 0px !important;
}

.content-title {
	text-align: left !important;
}

.signup-slider .slick-arrow {
    top: 150px !important;
}

section.sign-up {
	padding-bottom: 50px !important;
}

.center-width {
	width: 90%;
}

.heading h2 {
	font-size: 56px;
}

.heading p {
	font-size: 22px;
}

.slide-list {
    list-style: square;    
    margin-left: -32px;
    margin-bottom: 30px;
}

.slide-list li {   
    margin-bottom: 5px;
	line-height: 32px;
	color: #FFFFFF;
	font-size: 20px;
	margin-left: 30px;
}

.slide-list li:before {
    background: #fff;
    border-radius: 100%;
    color: #555;
    font-size: 1px;
    font-weight: 600;
    height: 5px;
    width: 5px;
    text-align: center;
    display: inline-block;
	line-height: 25px;	
}

.make-content p {
    color: #474747;
    font-size: 18px;
    font-family: 'Montserrat';
    opacity: 1;
    line-height: 30px;
    font-weight: 500;
}

.heading-wrapper h2 {
	font-size: 45px;
}

span.heading-text {
	font-size: 22px;
}

.heading-title {
    padding-top: 10px;
}

.font-bold-500 {
	font-weight: 500 !important;
}

.top-baner {
    background-repeat: no-repeat, repeat;
    margin: 0 auto;
    width: 100%;
    background-position: bottom;
    background-size: cover;
    display: flex;
    justify-content: center;
	align-items: center;
	background: #2a316b;
}

.inner-banner-img img {
    width: 100%;
    height: 100%;
}

.facebook-2x {
	padding-left: 3px !important;
	padding-right: 3px !important;
}

.opacity-zero{
	opacity: 0% !important;
}

.validation-error {
    font-size: 12px;
    color: #ec5990;
}

.contact-form h5 {
	padding-bottom: 5px !important;
}

.whatsapp-icon-color {
	color: #09b83e !important;
}
.whatsapp-icon-text {
	color: #09b83e !important;
}
.whatsapp-icon-text:hover {
	text-decoration: none !important;
}
.whatsapp-mobile {
	display: none;
}

.image-80 {
	width:80% !important;
	height:80% !important;
}

.text-align-left {
	text-align: left;
}

/*02-02-2022*/
.img-5 img {
    width: 1092px !important;
    position: relative !important;
    top: 156px !important;
    left: -189px !important;
    height: auto !important;
}
.img-5 img {
    width: 1093px;
    position: relative;
    top: 101px;
    left: -189px;
}
p.lest-text {
    padding-top: 20px;
}
.container-width {
    width: auto;
}
.center-width {
    width: 75%;
    margin: 0 auto;
}
.sliderbaner-title {
    width: 50%;
    float: left;
    padding-top: 71px;
}
.slider-lady-img {
    width: 50%;
    float: left;
    text-align: left;
    padding-bottom: 40px;
}
.slider-lady-img img {
    width: 90%;
    position: relative;
    left: 0px;
    top: 11px;
    z-index: 9999 !important;
    height: 90%;
   object-fit: contain;
}
.slider-baner ul.slick-dots {
    bottom: 25px !important;
}
.slider-baner .slick-dots li.slick-active button:before {
    opacity: unset !important;
    color: #F77E0B!important;
    font-size: 20px !important;
}
.slider-baner .slick-dots li button {
    /*background: #fff !important;*/
    opacity: 20%;
}
.slider-baner .slick-active button {
    opacity: unset !important;
    border: 0 !important;
}
/*end*/
/*01-02-2022*/
.content-wrapper h2.world-heading {
    padding-bottom: 60px;
}
.make-img img {
    width: 90%;
}
.make-content p {
    color: #474747;
    font-size: 21px;
    font-family: 'Montserrat';
    opacity: 1;
    line-height: 35px;
}
.make-content {
    width: 95%;
}
.icons img {
    width: 32px;
}
span.heading-text {
    color: #2A316B;
    font-size: 28px;
    font-family: 'Montserrat';
    font-weight: bold;
    line-height: 37px;
}
span.icons {
    padding-right: 6px;
}
.heading-title p {
    padding-top: 20px;
    padding-left: 43px;
    margin: 0;
}
.heading-title {
    padding-top: 30px;
}
.border-botam {
    border-bottom: 1px solid #d5cece;
}
.sign-up .slick-slider-sign.slick-slide.slick-cloned {
    width: 1109px !important;
}
.top-img {
    width: 40%;
    float: left;
    padding-top: 10px;
}
.sign-up .content-title.heading-desc {
    width: 60%;
    float: left;
    text-align: left;
}
.sign-up p {
    color: #474747;
    font-size: 20px;
    font-family: Montserrat !important;
    line-height: 30px;
    padding-bottom: 17px !important;
    margin: 0 !important;
}
.sign-up h2 {
    padding-bottom: 35px;
}
.number {
    font-size: 70px;
    color: #2A316B;
    opacity: 17%;
    font-family: 'Montserrat';
    font-weight: bold;
}
.text-title {
    color: #0B1320;
    font-size: 42px;
    font-family: 'Montserrat';
    opacity: 50%;
    font-weight: bold;
    line-height: 66px;
}
p.bg-title {
    background: #2680EB;
    color: #fff;
    padding: 0 !important;
    width: 18%;
    text-align: center;
    font-size: 14px;
    border-radius: 10px;
    position: absolute;
    left: 83px;
    bottom: -13px;
}
.top-slider-content {
    position: relative;
}
.signup-slider .slick-arrow {
    top: 214px !important;
}
.botem-img img {
    width: 510px;
}
/*end*/

/*.sliderbaner-title {
    width: 66%;
    float: left;
    padding-top: 71px;
}*/
.slider-baner .slick-next {
    right: 51px !important;
    top: 42% !important;
}
.slider-baner button.slick-prev.slick-arrow {
    top: 42% !important;
    left: 19px !important;
    z-index: 999;
}
.foter-top .logo-img img {
    width: 65%;
}
section.header.mobile {
    display: none;
}
.top-header {
    padding: 20px 0px 0px;
}
.header-title h1 {
    color: #0B1320;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
}
ul.icons {
    padding: 0;
    display: flex;
    margin: 0;
}
.header-title {
    text-align: right;
    padding-top: 6px;
}
.icons li {
    list-style: none;
    width: 33.42px;
    text-align: center;
    background: #000;
    height: 32px;
    margin-right: 15px;
    border-radius: 50px;
    padding-top: 4px;
}
.icons-link i {
    color: #FFFFFF;
    font-size: 18px;
}
.sign-login-btn {
    border: 1px solid #707070;
    padding: 3px 13px;
    border-radius: 10px;
    color: #000 !important;
    text-decoration: unset !important;
    font-weight: normal;
    margin-right: 12px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
}
.header-btn {
    padding-top: 6px;
    padding-left: 20px;
}
li.nav-item {
    list-style: none;
    text-align: center;
    display: inline;
    padding: 0px 5px !important;
}
ul.nav-menu {
    padding: 0px 28px 0px 0px;
    display: inline;
    margin: 0;
}
.nav-link {
    display: unset !important;
    padding: .5rem 1rem;
}
a.nav-link {
    color: #0B1320;
    font-size: 16px;
    line-height: 33px;
    font-family: Montserrat;
}
.foter-menu a.nav-link {
    color: #0B1320;
    font-size: 19px;
    line-height: 33px;
    font-family: 'Montserrat';
    font-weight: 600;
    padding: 0 !important;
}
.header-menu {
    padding-top: 10px;
}
.slider-baner {
    /*height: 534px !important;*/
    background: #2A316B;
    padding-top: 40px;
}
.top-baner {
    background-repeat: no-repeat, repeat;
    /*height: 567px;*/
    margin: 0 auto;
    width: 100%;
    background-position: bottom;
    background-size: cover;
    /*display: flex;
    justify-content: center;
    align-items: center;*/
}

header.hader {
    padding-bottom: 6px;
}
.heading h2 {
    font-size: 70px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 78px;
    margin: 0;
    padding-bottom: 25px;
    /*font-family: 'Montserrat', sans-serif;*/
    font-family: 'Montserrat';
}
.slick-slide img {
    display: unset !important;
}
.slider-img {
    text-align: right;
    padding-right: 52px;
    padding-top: 86px;
}
.heading p {
    color: #FFFFFF;
    font-size: 28px;
    line-height: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
}
.content-middle {
    display: flex;
    justify-content: center;
    align-items: center;
}
.slider-baner .slick-prev:before, .slick-next:before {
    color: white !important;
}
button.slick-prev.slick-arrow:before {
    content: "\f104" !important;
    font: normal normal normal 14px/1 FontAwesome;
    z-index: 1;
    border: 3px solid #fff !important;
    border-radius: 50px;
    padding: 5px 16px;
    font-size: 34px !important;
    font-weight: bold;
    opacity: unset !important;
}
button.slick-next.slick-arrow:before {
    content: "\f105" !important;
    font: normal normal normal 14px/1 FontAwesome;
    z-index: 1;
    border: 3px solid #fff !important;
    border-radius: 50px;
    padding: 5px 16px;
    font-size: 34px !important;
    font-weight: bold;
    opacity: unset !important;
}
.slick-next {
    right: 0 !important;
}
.slick-prev {
    left: -90px !important;
}
.slick-slider .slick-next:before {
    font-size: 50px !important;
    color: #fff!important;
}
.btans {
    border: 1px solid #FFFFFF;
    padding: 12px 21px;
    width: auto;
    text-align: center;
    float: left;
    margin-right: 17px;
    color: #FFFFFF !important;
    text-decoration: unset !important;
    font-size: 20px;
    font-family: 'Montserrat';
    border-radius: 5px;
    font-weight: normal;
}
.free-trial {
    background: #FF9100;
    border: 1px solid #FF9100;

}
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 50px !important;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.heading {
    padding-bottom: 20px;
    padding-left: 10px;
}
.slider-img img {
    width: 340px;
}
.content_boxs {
    display: flex;
    justify-content: center;
    align-items: center;
}
/*section.content-wrapper {
    padding: 120px 0px;
}*/
section.content-wrapper {
    padding: 70px 15px 70px 0px !important;
}
.heading-wrapper h2 {
    color: #0B1320;
    font-size: 50px;
    font-weight: bold;
    padding-bottom: 15px;
    margin: 0;
    font-family: Montserrat;
}
.heading-wrapper p {
    margin: 0;
    padding-bottom: 30px;
    color: #474747;
    font-size: 22px;
    font-family: 'Montserrat';
    line-height: 30px;
    font-weight: normal;
}
.heading-wrapper {
    text-align: center;
}
.center-boredr {
    border-left: 1px solid #777;
    height: 708px;
    text-align: center;
    margin-left: 80px;
    margin-right: 80px;
}
section.sign-up {
    padding-bottom: 150px;
}
.for_people {
    border-right: 1px solid #707070;
    padding: 0px 62px;
}
.for_teacher {
    padding: 0px 55px;
}
.top-img {
    text-align: left;
    padding-bottom: 36px;
}
.content-title h3 {
    color: #040404;
    font-size: 50px;
    font-weight: bold;
    font-family: 'Montserrat';
    margin: 0;
    padding-bottom: 25px;
}
.top-img img {
    height: auto;
    width: 82%;
}
.signup-slider .slick-arrow:before {
    color: #707070 !important;
}
.slick-slider-sign {
    padding: 0px 36px;
}
.content-title p {
    color: #474747;
    font-size: 20px;
    font-family: 'Montserrat';
    line-height: 30px;
    padding-bottom: 30px;
    margin: 0;
}
.content-title {
    text-align: center;
}
.sign-up .slick-prev {
    left: -55px !important;
}
.sign-up button.slick-next.slick-arrow:before {
    border: 3px solid #707070 !important;
}
.sign-up button.slick-prev.slick-arrow:before {
    border: 3px solid #707070 !important;
}
a.for-btn {
    color: #040404;
    font-size: 22px;
    font-family: 'Montserrat';
    width: 300px;
    border: 1px solid #040404;
    display: inline-block;
    line-height: 55px;
    text-decoration: unset;
    border-radius: 5px;
    text-align: center;
}

.teacher {
    background: #FF9100;
    border: 1px solid #FF9100 !important;
    color: #fff !important;
}
.journey h2 {
    padding-bottom: 45px;
}
.journey {
    padding-bottom: 73px;
}
.sign-up-content {
    padding-bottom: 45px;
}
.img-box-slider .slick-prev:before{
    font-size: 50px !important;
    color: #777 !important;
}
.img-box-slider .slick-next:before {
    font-size: 50px !important;
    color: #777 !important;
}
.vedio-sec {
    background-image: url(../images/MiddleBanner_2x.png);
    background-repeat: no-repeat, repeat;
    height: 567px;
    margin: 0 auto;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vedio-title h3 {
    color: #fff;
    z-index: 9999;
    position: relative;
    font-size: 50px;
    font-family: 'Montserrat';
    line-height: 60px;
    font-weight: normal;
}
.vedio-title {
    text-align: center;
}
.vedio-icon {
    text-align: center;
    position: relative;
    z-index: 999;
}
a.icon-bg {
    background: #FFFFFF;
    padding: 25px 29px;
    border-radius: 50px;
    color: #574BE9;
    font-size: 18px;
}
.vedio-title {
    padding-bottom: 50px;
}
section.why-teacher {
    background: #0B1320;
    padding-top: 70px;
    /*padding-bottom: 70px;*/
}
.teacher-title h4 {
    color: #FFFFFF;
    font-size: 50px;
    font-family: 'Montserrat';
    font-weight: bold;
    padding-bottom: 50px;
}
.teacher-title {
    text-align: center;
}
.teacher-title p {
    color: #FFFFFF;
    font-size: 22px;
    font-family: 'Montserrat';
    padding-bottom: 25px;
}
.teacher-title {
    padding-bottom: 60px;
}
.top-icon {
    padding-bottom: 30px;
}
.icon-box-title h4 {
    color: #FFFFFF;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Montserrat';
    padding-bottom: 10px;
    margin: 0;
}
.icon-box-title p {
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'Montserrat';
}
a.read-more {
    color: #D82019;
    text-decoration: unset;
}
.teacher-icon-box {
    text-align: center;
    padding-bottom: 80px;
}
.web {
    background: #FFBB00;
}
.online {
    background: #08C7FB;
}
.icon-box-title {
    height: 180px;
}
.live-video {
    background: #AD64C4;
}
.schudle {
    background: #EE5A23;
}
.chat {
    background: #04D7AB;
}
.manzment {
    background: #7269D4;
}
.icon-size{
    width: 142px;
    height: 142px;
    padding-top: 44px;
    border-radius: 100px;
    color: #fff;
    font-size: 63px;
    border: 1px solid #000;
    text-align: center;
}
.teacher-icon-box {
    width: 80%;
    margin: 0 auto;
}
section.lorem-sec {
    background-image: url(../images/RedBG_1x.png);
    background-repeat: no-repeat, repeat;
    margin: 0 auto;
    width: 100%;
    background-position: center;
    background-size: cover;
    padding: 62px 0px 0px;
}
/*.lorem-img {
    padding-top: 60px;
}*/
.lorem-img img {
    width: 90%;
    height: 90%;
}
.lorem-sec h2 {
    font-size: 41px;
    line-height: 55px;
    font-family: 'Montserrat';
}
.lorem-sec .heading p {
    color: #FFFFFF;
    font-size: 21px;
    line-height: 30px;
    font-family: 'Montserrat';
    font-weight: normal;
    padding-bottom: 20px;
}
.tesmonial-img img {
    width: 60%;
    height: 200px;
    object-fit: cover;
    border-radius: 100px;
}
.img-slider img {
    width: 150px;
    height: 124px;
    object-fit: contain;
}
.tesmonial-img {
    width: 30%;
    float: left;
    padding-top: 48px;
}
.tesmonial-title {
    padding-top: 46px;
    width: 70%;
    float: right;
}
.tesmonial-title h4 {
    color: #040404;
    font-size: 50px;
    ont-family: 'Montserrat';
    font-weight: bold;
}
.tesmonial-title p {
    color: #474747;
    font-size: 22px;
    font-family: 'Montserrat';
}
p.name {
    color: #474747;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: bold;
}
section.testmonila {
    padding-bottom: 100px;
    padding-top: 100px;
    border-bottom: 1px solid #c1b7b7;
    margin-bottom: 30px;

}
.slick-dots li button {
    font-size: 0px;
    line-height: 0;
    display: block;
    width: 18px !important;
    height: 18px !important;
    padding: 0 !important;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: #707070 !important;
    border-radius: 50px !important;
}
.slick-dots li.slick-active button:before {
    opacity: unset !important ;
    color: #2680EB !important;
    font-size: 20px !important;
}
.slick-dots {
    bottom: -61px !important;
}
.slick-dots li button:before {
    font-size: 0 !important;
}
.slider-img img {
   width: 340px;
}
.img-slider .slick-prev:before, .slick-next:before {
    color: #707070 !important;
}
.img-slider .slick-next:before {
    color: #707070 !important;
}
.img-slider .slick-prev {
    top: 43% !important;
}
.img-slider .slick-next {
    top: 43% !important;
}
section.img_crousel {
    padding: 100px 0px;
}
.img_crousel button.slick-prev.slick-arrow:before {
    border: 3px solid #777 !important;
}
.img_crousel button.slick-next.slick-arrow:before {
    border: 3px solid #707070 !important;
}
.img_crousel p {
    padding-bottom: 70px;
}
section.blog {
    background: #d6eeef;
    padding: 70px 0px;
}
.blog-title h5 {
    color: #040404;
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: bold;
}
.blog-img {
    padding-bottom: 30px;
}
.blog-title a {
    text-decoration: unset;
}
.blog-title p {
    color: #040404;
    font-size: 20px;
    font-family: 'Montserrat';
}
.blog-title {
    text-align: center;
}
.blog-img img {
    width: 100%;
    height: 220px;
    object-fit: cover;
}
.global .teacher-title {
    padding-bottom: 0;
}
section.global {
    background: #000;
    padding: 85px 0px 0px;
}

footer.foter-top {
    padding-top: 30px;
    padding-bottom: 30px;
}
.foter-content {
    padding: 6px;
}
.foter-icons i {
    color: #040404;
    font-size: 30px;
}
span.foter-icons {
    padding-right: 12px;
}
span.icon-title {
    color: #040404;
    font-size: 17px;
    font-family: 'Montserrat';
}
.icon-title a {
    color: #040404 !important;
    font-size: 17px;
    font-family: 'Montserrat';
    text-decoration: unset !important;
}
.foter-menu ul.nav-menu {
    display: block;
    text-align: left;
}
.foter-menu li.nav-item {
    text-align: left;
}
.foter-top .header-icon {
    text-align: left;
   padding-top: 0px;
}
.icon-title-content {
    text-align: center;
    padding-bottom: 25px;
    color: #0B1320;
    font-size: 19px;
    line-height: 33px;
    font-family: 'Montserrat';
    font-weight: 600;
}
footer.foter-botam {
    background: #F1F1F1;
    padding-top: 15px;
    padding-bottom: 15px;
}
.copy-right p {
    color: #040404;
    font-size: 16px;
    margin: 0;
}
.about-baner {
    background-image: url(../images/aboutUs.png);
    background-position: center;
}
.small-title h2 {
    font-size: 59px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 72px;
    margin: 0;
    padding-bottom: 25px;
    font-family: 'Montserrat', sans-serif;
}

section.counting-number {
    padding-bottom: 70px;
}
.count-up h3 {
    color: #0b1320;
    font-size: 35px;
    font-family: 'Montserrat';
    font-weight: bold;
    padding-bottom: 20px;
    text-align: left;
}
.counter{
    text-align: left;
}
.count-up {
    border: 1px solid #707070;
    padding: 30px;
    margin: 30px;
}
.counter-count {
    font-size: 65px;
    font-weight: bold;
    color: #40e9ba;
    text-align: left;
    font-family: 'Montserrat';
    margin: 0;
}
.oversion-content {
    width: 70%;
    text-align: left;
}
.Subscription-box {
    text-align: center;
}
.Subscription-btn a {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 3px 0px;
    color: #FEFEFF;
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: bold;
    text-decoration: unset;
}
.stat a {
    background: #FF9100;
}
.contact a {
    background: #233B5F;
}
h3 {
    color: #0B1320;
    font-size: 40px;
    font-family: Montserrat;
    font-weight: bold;
}
.Subscription-content h3 {
    color: #0B1320;
    font-size: 40px;
    font-family: 'Montserrat';
    font-weight: bold;
}
.Subscription-content p {
    color: #474747;
    font-size: 20px;
    font-family: 'Montserrat';
}
.Subscription-content {
    border: 1px solid #707070;
    padding: 20px 10px;
}
.Subscription-sec .heading-desc p {
    padding-bottom: 65px;
}
.Subs-sec {
    padding: 70px 0px  145px!important;
}
.faq-title {
    width: 71%;
    margin: 0 auto;
    text-align: center;
}
input#search {
    color: #707070;
    font-size: 25px;
    font-family: 'Montserrat';
    padding: 15px;
    background: #fff;
    border: 1px solid #FFFFFF;
    height: 65px;
}
section.faq-sec {
    padding: 50px 0px;
}
.accordion .card-header:before {
    font-family: 'FontAwesome';  
    content: "\f068";
    float: left; 
    margin-top: 20px;
    color: #D82019;
}
.accordion .card-header.collapsed:before {
    content: "\f067"; 
    margin-top: 20px;
    color: #D82019;
}
a.card-title {
    padding-left: 35px;
    width: 82%;
    display: flex;
    color: #040404;
    font-size: 25px;
    font-family: 'Montserrat';
    text-decoration: unset;
    line-height: 33px;
}
.card.mb-0 {
    border: 0;
}
.card-header {
    border-bottom: 0 !important;
    border-top: 1px solid #000;
}
.card-body {
    width: 90%;
    margin-left: 35px;
    padding-top: 10px;
}
.card-body p {
    color: #474747;
    font-size: 20px;
    font-family: 'Montserrat';
}
.contac-baner-title {
    text-align: center;
}
section.contact-sec {
    padding: 50px 0px;
}
.contact-icons i {
    color: #0B1320;
    font-size: 60px;
}
span.contact-icons {
    padding-right: 15px;
}
span.contact-title {
    font-size: 38px;
    color: #0B1320;
    font-weight: bold;
    font-family: 'Montserrat';
}
.contact-content {
    padding-bottom: 20px;
}
.img_crousel .slick-arrow:before {
    color: #707070 !important;
}
.country-content h4 {
    color: #0B1320;
    font-size: 22px;
    font-family: 'Montserrat';
    font-weight: bold;
}
.country-content {
    padding-left: 80px;
    padding-bottom: 30px;
}
.icon-content {
    padding-bottom: 25px;
}
.country-content p {
    color: #040404;
    font-family: 'Montserrat';
    font-size: 18px;
    /*width: 62%;*/
    font-weight: 500;
}
.testmonila .slick-arrow {
    display: none !important;
}
.mail a {
    font-size: 26px;
}
.with-us h1 {
    font-size: 24px;
    padding-bottom: 14px;
}
.contact-form {
    border: 1px solid #707070;
    padding: 55px 30px;
    width: 83%;
    border-radius: 10px;
    float: right;
    margin-top: 30px;
}
.contact-form h5 {
    font-size: 34px;
    color: #0B1320;
    font-weight: bold;
    padding-bottom: 30px;
    font-family: 'Montserrat';
}
.form-control {
    font-size: 20px;
    height: 50px;
}
button.btn.sumbit-btn {
    width: 100%;
    background: #0B1320;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
}
.contact-form p {
    color: #707070;
    font-size: 16px;
    padding-top: 20px;
}
.scrol-icon a {
    color: #fff;
    background: #ff9100;
    padding: 6px 16px;
    font-size: 24px;
    border-radius: 50px;
}
.scrol-icon {
    position: fixed;
    right: 86px;
    bottom: 34px;
}
.hader .logo-img img {
    width: 45%;
}
.border {
    border: 1px solid #dee2e600!important;
}
section.mobile {
    display: none;
}
.faq {
    background: #2A316B;
}
footer.foter-top.mobile {
    display: none;
}
.foter-menu li.nav-item {
    list-style: none;
    display: block;
    padding: 0px 5px !important;
}
.signup-slider .slick-arrow {
    top: 306px;
}
.sign-up .slick-next {
    right: -20px !important;
}
.slick-slider-sign.slick-slide.slick-active {
    border-right: 0px solid;
}
.foter-top .icons li {
    list-style: none;
    background: none;
    width: 33.33%;
    margin-right: 0;
}
.foter-top ul.icons {
    padding: 0;
    display: flex;
    margin: 0;
    text-align: center;
}
.foter-top a.icons-link {
    text-align: center;
    background: #000;
    height: 32px;
    margin-right: 0;
    border-radius: 50px;
    padding: 12px 14px;
}
.foter-top .header-icon {
    text-align: center;
}
.email-content {
    padding-top: 30px;
}
.foter-menu {
    width: 33.33%;
}
.foter-all-menu {
    display: flex;
}



@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px){

    .img_crousel .slick-arrow:before {
        color: #787878 !important;
    }
    .header-menu .nav-link {
        display: unset !important;
        padding: 0px 20px;
    }
    .header-menu {
        padding-top: 0;
    }
    .slider-img {
        padding: 35px 0px 0px;
    }
    .sign-up .slick-prev, .slick-slider .slick-next {
        top: 51% !important;
    }
    .slider-baner button.slick-next.slick-arrow {
        top: 43% !important;
    }
    .sign-up .slick-prev, .slick-slider .slick-next {
        top: 51% !important;
        right: -18px !important;
    }
    .for_people {
        padding: 0px 20px;
    }
    .for_teacher {
        padding: 0px 20px;
    }
    button.slick-prev.slick-arrow:before {
        padding: 3px 11px;
        font-size: 24px !important;
    }
    button.slick-next.slick-arrow:before {
        padding: 3px 11px;
        font-size: 24px !important;
    }
    .icon-box-title {
        height: 218px;
    }
    .tesmonial-img img {
        width: 75%;
        height: 162px;
    }
    .btans {
        padding: 12px 16px;
    }
    section.mobile {
        display: none;
    }
    .slick-slider-sign.slick-slide.slick-cloned {
        width: 463px !important;
    }
      /* .slick-slider {
        width: 690px !important;
    }*/
    .foter-menu a.nav-link {
        padding: 0 !important;
    }
    .heading h2 {
        font-size: 40px;
    }
    .heading p {
        font-size: 16px;
    }
    .btans {       
        font-size: 13px;
    }
    .btans {
        padding: 12px 14px;
    }
    li.nav-item {
        padding: 0px 1px !important;
    }
    .sliderbaner-title {
        padding-left: 15px;
    }
    .slick-slider button.slick-prev.slick-arrow {
        left: -36px !important;
    }
    .slick-prev:before, .slick-next:before {
        font-size: 36px !important;
    }
    .slick-slider .slick-next:before {
        font-size: 36px !important;
    }
    .sign-login-btn {
        padding: 3px 9px;
        margin-right: 3px;
        font-size: 14px;
    }
    .sign-up .slick-next {
        right: 0px !important;
    }
    .header-btn {
        padding-top: 6px;
        text-align: left;
        padding-right: 11px;
    }
    ul.nav-menu {
        padding: 0px 0px 0px 0px !important;
        display: flex;
        margin: 0;
    }
    li.nav-item {
        width: auto;
    }
    a.nav-link {
        font-size: 16px;
    }
    .top-header {
        padding: 20px 0px 0px 0px;
    }
    .hader .logo-img{
        position: relative;
        top: -20px;
    }
    /*.logo-img img {

        width: 100%;

    }*/
    header.hader {
        padding-bottom: 0;
    }
   .top-baner {
        background-repeat: no-repeat, repeat;
        height: 430px !important;
        padding-top: 49px;
        display: block;
    }
    h2 {
        font-size: 40px !important;
        line-height: 50px !important;
    }
    section.content-wrapper {
        padding: 60px 0px 30px;
    }
    .heading-wrapper p {
        padding-bottom: 0;
        font-size: 16px;
    }
    h3 {
        font-size: 40px !important;
    }
    p {
        font-size: 16px !important;
    }
    .img-box-slider .slick-prev:before {
        font-size: 36px !important;
    }
    .img-box-slider button.slick-prev.slick-arrow {
        left: 0px !important;
    }
    .img-box-slider button.slick-next.slick-arrow {
        right: -16px;
    }
    .slider-img img {
        width: 80%;
    }
    .top-img img {
        width: 100%;  
    }
    a.for-btn {
        font-size: 19px;
        width: 256px;
        font-weight: normal !important;
    }
    .center-boredr {
        margin-left: 48px;
    }
    .vedio-title h3 {
        font-size: 24px !important;
        line-height: 36px;
    }
    .vedio-sec {
        height: auto;
    }
    .overlay {
        height: 400px;
    }
    h4 {
        font-size: 40px !important;
    }
    .why-teacher p {
        padding-bottom: 0;
        width: 70%;
        margin: 0 auto;
    }
    .icon-box-title h4 {
        color: #FFFFFF;
        font-size: 30px !important;
    }
    .teacher-icon-box {
        width: 100%;
    }
    .icon-size {
        width: 129px;
        height: 129px;
        font-size: 52px;

    }
    .foter-top .header-icon {
        float: right;
    }
    .scrol-icon {
        position: fixed;
        right: 45px;
        bottom: 34px;
        bottom: 104px;
    }
    footer.foter-top {
        padding-bottom: 66px;
    }
    footer.foter-botam {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .count-up {
        border: 1px solid #707070;
        padding: 17px;
        margin: 12px;
    }
    .counter-count {
        font-size: 42px !important;
    }
    .count-up h3 {
        font-size: 25px !important;
    }
    section.counting-number {
        padding-bottom: 0;
    }
    .oversion-content {
        width: 100%;
		text-align: left;
		font-weight: 500;
    }
    .Subscription-btn a {
        font-size: 19px;
    }
    .Subscription-content h3 {
        font-size: 24px !important;
    }
    .Subscription-content p {
        font-size: 12px !important;
    }
    .Subs-sec {
        padding: 51px 0px  60px!important;
    }
    input#search {
        font-size: 18px;
        height: 53px;
    }
    a.card-title {
        font-size: 17px;
        line-height: 26px;
    }
    span.contact-title {
        font-size: 32px;
    }
    .contact-icons i {
        font-size: 40px;
    }
    .country-content h4 {
        font-size: 16px !important;
    }
    .country-content {
        padding-left: 61px;
        padding-bottom: 16px;
    }
    .mail a {
        font-size: 20px;
    }
    .contact-form p {
        font-size: 12px !important;
    }
    .contact-form {
        padding: 26px 30px;
        width: 100%;
        margin-top: 0;
    }
     .contact-form h5 {
        font-size: 27px;
    }
    .form-control {
        font-size: 16px;
        height: 40px;
    }

    .col-lg-4.col-sm-6.blog_img {
        flex: 0 0 33%;
        max-width: 33%;
    }
    .blog-title h5 {
        color: #040404;
        font-size: 20px;
    }
    .blog-title p {
        font-size: 14px !important;
    }
    .sliderbaner-title {
        width: 50%;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px){
	.slider-top-space {
		top: 60px !important;
	}
    .foter-top .icons li {
        list-style: none;
        display: inline;
        background: none;
        width: 30.33%;
        margin-right: 0;
    }
    .foter-top ul.icons {
        padding: 0;
        display: flex;
        margin: 0;
    }
    .hader .logo-img img {
        width: 100%;
    }
    .slider-img {
        padding: 35px 0px 0px;
    }
    .sign-up .slick-prev, .slick-slider .slick-next {
        top: 51% !important;
    }
    .slider-baner button.slick-next.slick-arrow {
        top: 43% !important;
    }
    .for_people {
        padding: 0px 20px;
    }
    .for_teacher {
        padding: 0px 20px;
    }
    button.slick-prev.slick-arrow:before {
        padding: 3px 11px;
        font-size: 24px !important;
    }
    button.slick-next.slick-arrow:before {
        padding: 3px 11px;
        font-size: 24px !important;
    }
    .icon-box-title {
        height: 218px;
    }
    .tesmonial-img img {
        width: 75%;
        height: 162px;
    }
    .btans {
        padding: 12px 16px;
    }
    section.mobile {
        display: none;
    }
    .foter-menu a.nav-link {
        padding: 0 !important;
        font-size: 14px;
    }
    .foter-top .logo-img img {
        width: 100%;
    }
    .heading h2 {
        font-size: 40px;
    }
    .heading p {
        font-size: 16px;
    }
    .btans {        
        font-size: 13px;
    }
    .btans {
        padding: 12px 14px;
    }
    li.nav-item {
        padding: 0px 1px !important;
    }
    .sliderbaner-title {
        padding-left: 15px;
    }
    .sliderbaner-title {
        padding-top: 26px;
    }
   .slick-slider button.slick-prev.slick-arrow {
        left: 1px !important;
        z-index: 999;
    }
    .slick-prev:before, .slick-next:before {
        font-size: 36px !important;
    }
    .slick-slider .slick-next:before {
        font-size: 36px !important;
    }
    .sign-login-btn {
        padding: 3px 9px;
        margin-right: 3px;
        font-size: 14px;
    }
    .sign-up .slick-next {
        right: 87px !important;
        z-index: 999;
    }
    .header-btn {
        padding-top: 6px;
        text-align: right;
        padding-right: 11px;
    }
    ul.nav-menu {
        padding: 0px 0px 0px 0px !important;
        display: flex;
        margin: 0;
    }
    li.nav-item {
        width: auto;
    }
    a.nav-link {
        font-size: 16px;
    }
    .top-header {
        padding: 20px 0px 0px 0px;
    }
    .hader .logo-img{
        position: relative;
        top: -20px;
    }
    header.hader {
        padding-bottom: 0;
    }
    .top-baner {
        background-repeat: no-repeat, repeat;
        height: 530px !important;
        padding-top: 81px;
        display: block;
    }
    h2 {
        font-size: 35px !important;
        line-height: 40px !important;
    }
    section.content-wrapper {
        padding: 60px 0px 30px;
    }
    .heading-wrapper p {
        padding-bottom: 0;
        font-size: 16px;
    }
    h3 {
        font-size: 40px !important;
    }
    p {
        font-size: 18px !important;
    }
    .img-box-slider .slick-prev:before {
        font-size: 36px !important;
    }
    .img-box-slider button.slick-prev.slick-arrow {
        left: 0px !important;
    }
    .img-box-slider button.slick-next.slick-arrow {
        right: -16px;
    }
    .slider-img img {
        width: 80%;
    }
    .top-img img {
        width: 100%;  
    }
    a.for-btn {
        font-size: 19px;
        width: 256px;
        font-weight: normal !important;
    }
    .center-boredr {
        margin-left: 48px;
    }
    .vedio-title h3 {
        font-size: 24px !important;
        line-height: 36px;
    }
    .vedio-sec {
        height: auto;
    }
    .overlay {
        height: 400px;
    }
    h4 {
        font-size: 40px !important;
    }
    .why-teacher p {
        padding-bottom: 0;
        width: 70%;
        margin: 0 auto;
    }
    .icon-box-title h4 {
        color: #FFFFFF;
        font-size: 30px !important;
    }
    .teacher-icon-box {
        width: 100%;
    }
    .icon-size {
        width: 129px;
        height: 129px;
        font-size: 52px;

    }
    .foter-top .header-icon {
        float: right;
    }
    .scrol-icon {
        position: fixed;
        right: 45px;
        bottom: 34px;
        bottom: 104px;
    }
    footer.foter-top {
        padding-bottom: 66px;
    }
    footer.foter-botam {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .count-up {
        border: 1px solid #707070;
        padding: 17px;
        margin: 12px;
    }
    .counter-count {
        font-size: 42px !important;
    }
    .count-up h3 {
        font-size: 25px !important;
    }
    section.counting-number {
        padding-bottom: 0;
    }
    .oversion-content {
        width: 100%;
		text-align: left;
		font-weight: 500;
    }
    .Subscription-btn a {
        font-size: 19px;
    }
    .Subscription-content h3 {
        font-size: 24px !important;
    }
    .Subscription-content p {
        font-size: 12px !important;
    }
    .Subs-sec {
        padding: 51px 0px  60px!important;
    }
    input#search {
        font-size: 18px;
        height: 53px;
    }
    a.card-title {
        font-size: 17px;
        line-height: 26px;
    }
    span.contact-title {
        font-size: 32px;
    }
    .contact-icons i {
        font-size: 40px;
    }
    .country-content h4 {
        font-size: 16px !important;
    }
    .country-content {
        padding-left: 61px;
        padding-bottom: 16px;
    }
    .mail a {
        font-size: 20px;
    }
    .contact-form p {
        font-size: 12px !important;
    }
    .contact-form {
        padding: 26px 30px;
        width: 100%;
        margin-top: 0;
    }
     .contact-form h5 {
        font-size: 24px;
    }
    .form-control {
        font-size: 16px;
        height: 40px;
    }

    .col-lg-4.col-sm-6.blog_img {
        flex: 0 0 33%;
        max-width: 33%;
    }
    .blog-title h5 {
        color: #040404;
        font-size: 20px;
    }
    .blog-title p {
        font-size: 14px !important;
    }
    .vedio-sec {
        background-image: url(../images/tab-middle-baner.png);
    }
    .about-baner {        
        background-position: center;
        height: 334px !important;
    }
    .ourvision {        
        height: 334px !important;
    }
    .Subscription-baner {        
        height: 334px !important;
    }
    .contact-baner {        
        height: 334px !important;
    }
    .slick-slider-sign {
        padding: 0px 18px;
    }
    .img_crousel .slick-arrow:before {
        color: #787878 !important;
    }
    .img_crousel .slick-arrow:before {
	    color: #707070 !important;
	}
    /*02-02-2022*/
    .slider-lady-img img {
        width: 80%;
        height: auto;
    }
    .container-width {
        width: 820px;
    }
    .lorem-img img {
        width: 334px;
        height: 377px;
    }
    .sign-up .slick-slider-sign.slick-slide.slick-cloned {
        width: 668px !important;
    }
    p.bg-title {
        width: 32%;
        bottom: 25px;
    }
    .top-slider-content {
        padding-bottom: 48px;
    }
    .sign-up .slick-prev, .slick-slider .slick-next {
        top: 108% !important;
    }
    .slider-baner .slick-next {
        right: 29px !important;
    }
    .center-width {
        width: 84%;
        margin: 0 auto;
    }
    .img-5 img {
        width: 446px !important;
        position: relative !important;
        top: 83px !important;
        left: -76px !important;
        height: auto !important;
    }

    /*end*/
}

@media only screen and (min-device-width: 415px) and (max-device-width: 767px){
    p {
        font-size: 16px !important;
        line-height: 30px;
    }
    h4 {
        font-size: 30px !important;
    }
    h3 {
        font-size: 30px !important;
    }
    .img_crousel .slick-arrow:before {
        color: #787878 !important;
    }

    .sign-up .slick-arrow {
        top: 53% !important;
    }
    .sliderbaner-title {
        padding-left: 1px !important;
    }
    footer.foter-top.desktop {
        display: none;
    }
    footer.foter-top.mobile {
        display: block;
    }
    ul.slick-dots {
        position: relative;
    }
    .slick-slider-sign {
        padding: 0px 0px;
    }
    .slick-slider-sign.slick-slide.slick-current.slick-active {
        border-right: 0;
    }
    .why-teacher .slick-arrow {
        display: block !important;
    }
    .tesmonial-title h4 {
        position: absolute;
        top: -166px;
        font-size: 23px !important;
    }
    .foter-content {
        text-align: center;
    }
    .foter-top .logo-img {
        text-align: center;
    }
    .foter-menu ul.nav-menu {
        padding: 0 !important;
    }
    .foter-menu a.nav-link {
        padding: 0;
        font-size: 15px;
    }
    .foter-content {
        padding: 2px;
    }
    .icon-title-content {
        padding: 0;
        float: unset;
    }
    ul.icons {
        padding-left: 111px;
        display: flex;
        margin: 0;
    }
    .img_crousel .slick-arrow:before {
        color: #707070 !important;
    }
    .slick-slider-sign.slick-slide.slick-cloned {
        width: 300px !important;
    }
    .foter-top ul.icons {
        padding: 16px;
        display: flex;
        margin: 0;
    }
    .heading h2 {
        font-size: 20px !important;
        color: #FFFFFF;
        font-weight: bold;
        line-height: 21px;
        margin: 0;
        padding-bottom: 25px;

    }
    .for_people {
        padding: 0;
        border: 0;
    }
    .sign-up .slick-next {
        right: -11px !important;
    }
    .content-title {
        text-align: center;
        padding: 13px;
    }

    button.slick-prev.slick-arrow:before {
        padding: 5px 10px;
        font-size: 17px !important;
    }
    button.slick-next.slick-arrow:before {
        padding: 5px 11px;
        font-size: 17px !important;
    }
    section.mobile {
        display: block;
    }
    .header-btn {
        text-align: right;
    }
    .tesmonial-img h4 {
        font-size: 30px;
        font-weight: bold;
    }
    section.testmonila {
        padding-bottom: 8px;
    }
    .img_crousel .logo-slider {
        width: 100px !important;
    }
    .img-slider img {
        width: 84px;
        height: 124px;
        object-fit: contain;
    }
    .img-slider button.slick-prev.slick-arrow {
        left: -35px !important;
    }
    .img-slider button.slick-next.slick-arrow {
        top: 43% !important;
    }
    #why-teacher .slick-arrow {
        top: 21% !important;
    }
    #why-teacher ul.slick-dots {
        display: none !important;
    }
    .teacher-icon-box {
        width: auto;
        margin: 0 auto;
    }
    .teacher-icon-box {
        text-align: center;
        padding-bottom: 0px;
    }
    #why-teacher .slick-arrow:before {
        color: #707070 !important;
        border: 3px solid #707070 !important;
    }
   #why-teacher button.slick-next {
        right: 14px !important;
    }
    .sign-up-mobile {
        height: auto !important;
        padding-bottom: 132px !important;
    }
    .count-up {
        border: 1px solid #707070;
        padding: 29px 21px 90px;
        margin: 10px;
    }
    .country-content h4 {
        font-family: unset;
        font-size: 16px !important;
        padding-bottom: 10px;
    }
    .oversion-content {
		text-align: left;
		font-weight: 500;
    }
    .faq {
        background: #2A316B;
    }
    .foter-top li.nav-item {
        display: block;
    }
    .foter-menu {
        padding-bottom: 20px;
    }
    .foter-top .logo-img img {
        width: 37%;
        padding-bottom: 30px;
    }
    .header-title.heading-desc {
        text-align: center;
        padding-top: 30px;
    }

    footer.foter-top {
        padding-bottom: 60px;
    }
    .scrol-icon {
        position: fixed;
        right: 8px;
        bottom: 7px;
    }
    .heading p {
        color: #FFFFFF;
        font-size: 13px;
        line-height: 18px;
        font-family: 'Montserrat';
    }
    .slick-prev {
        left: 0px !important;
    }
    .sliderbaner-title {
        padding-left: 30px !important;
    }
    .slider-img {
        text-align: left;
        position: relative;
        left: -70px;
        top: 1px;
        z-index: 9999;
    }
    .slider-img img {
        width: 134px;
    }

    .slick-next {

        right: 155px !important;

    }
    .slick-prev:before, .slick-next:before {

        font-family: 'slick';

        font-size: 25px !important;

    }

    .slick-slider .slick-next:before {

        font-size: 25px !important;

        color: #fff!important;

    }

    .slick-prev {

        left: -7px !important;

    }

    .btans {

        padding: 5px 5px;
        

        margin-bottom: 6px;

        font-size: 14px;

        border-radius: 5px;

    }

    .col-lg-6.col-sm-6.content_box {

        padding-right: 0px;

        padding-left: 6%;

        flex: 0 0 37% !important;

        max-width: 37% !important;

    }

    .col-lg-6.col-sm-6.sd_img {

        padding-left: 0px;

    flex: 0 0 30% !important;

        max-width: 30% !important;

    }

    .top-baner {

        background-repeat: no-repeat, repeat;

        height: 360px;

    }

    section.content-wrapper {

        padding: 40px 0px;

    }

    .heading-wrapper h2 {

        color: #0B1320;

        line-height: 35px;

        font-size: 30px;

    }

    .heading-wrapper p {

        padding-bottom: 5px !important;

        padding-bottom: 30px;

        font-size: 15px;

		line-height: 30px;
		
		font-weight: 500;

    }

	.text-title {		
		font-size: 30px;		
	}

    a.for-btn {

        font-size: 14px;

        width: 200px;

        line-height: 35px;

    }

    .content-title h3 {

        color: #040404;

        font-size: 20px;

    }

    .content-title p {

        color: #474747;

        font-size: 13px;

        font-family: 'Montserrat';

		line-height: 30px;
		
		font-weight: 500;

    }

    .col-lg-5.col-sm-5.for_people {

        flex: 0 0 33.666667% !important;

        max-width: 33.666667% !important;

    }



    .col-lg-2.col-sm-2.border {

        flex: 0 0 1.666667%;

        max-width: 1.666667%;

        padding: 0px;

    }

    .center-boredr {

        border-left: 1px solid #777;

        height: 434px;

        text-align: center;

        margin-left: 3px;

        margin-right: 0px;

    }

    .col-lg-5.col-sm-5.for_people img {

        height: 107px;

    }

    .col-lg-5.col-sm-5.for_teacher img {

        height: 107px;

    }

    section.sign-up {

        padding-bottom: 45px;

    }

    .img-box-slider.slick-initialized.slick-slider {

        height: 441px;

    }

    .img-box-slider .slick-prev:before {

        font-size: 22px !important;

        color: #777 !important;

    }

    .img-box-slider.slick-slider .slick-next:before {

        font-size: 17px !important;

        color: #7777!important;

    }

    .vedio-title h3 {
        font-size: 17px;
        line-height: 30px;
    }

    .overlay {

        height: 500px;

    }

    .vedio-sec {
        height: auto;
    }
    .vedio-sec {
        background-image: url(../images/mobile-middle-baner.png);
    }
    .lorem-img img {
        width: 100%;
        height: auto;
    }
    .lorem-sec .heading.heading-desc {
        padding-bottom: 42px;
    }
    .botem-img img {
        width: 100%;
    }

    .teacher-title h4 {
        font-size: 30px;
        padding-bottom: 5px;
    }

    .teacher-title p {
        font-size: 12px;
        padding-bottom: 0px;
        line-height: 30px;
    }

    .icon-size {

        width: 116px;

        height: 112px;

        padding-top: 30px;

        font-size: 55px;

    }

    .icon-box-title h4 {

        font-size: 20px;

        padding-bottom: 5px;

    }

    .icon-box-title p {

        font-size: 15px;

    }

    .icon-box-title {

        height: 138px;

    }

    .icon-box-title.height1 {

        height: 90px;

    }

    .icon-box-title.height2 {

        height: 150px;

    }

    .icon-box-title.height3 {

        height: 116px;

    }

    .icon-box-title.height4 {

        height: 90px;

    }

    .icon-box-title.height5 {

        height: 90px;

    }

    section.why-teacher {

        background: #0B1320;

        padding-bottom: 40px;

    }

    .tesmonial-img img {
        width: 38%;
        height: 116px;
        object-fit: cover;
    }

    .tesmonial-img {
        width: 100%;
        float: unset !important;
        padding-top: 48px;
        text-align: center;
    }

    p.name {

        color: #474747;

        font-size: 17px;

    }

    .tesmonial-title h4 {

        font-size: 24px;

    }

    .tesmonial-title p {

        font-size: 15px;

    }

    .tesmonial-title {
        padding-top: 20px;
        width: 100%;
        float: right;
        text-align: center;
        position: relative;

    }

    .tesmonial-title p {

        width: 65%;

    }

    .slick-dots {
        width: 100% !important;
        bottom: 0px !important;
    }

    section.testmonila {

        padding-top: 30px;

    }

    section.img_crousel {

        padding: 20px 0px;

    }

    section.img_crousel h2 {

        font-size: 22px !important;

        line-height: 30px;

        padding-bottom: 15px;

    }

    .blog-title h5 {

        font-size: 24px;

    }

    .blog-title p {

        font-size: 17px;

    }

    section.blog {

        padding-bottom: 40px;

    }

    section.global {

        background: #000;

        padding: 50px 20px;

    }
    .teacher-title.heading-desc a {
        font-size: 15px;
    }
    .slick-slider .slick-next:before {
        font-size: 25px !important;
    }
    .sign-up .slick-arrow:before {
        color: #707070 !important;
    }
    .slick-slider .slick-next:before {
        font-size: 25px !important;
    }

    .img-box-slider .slick-prev:before {
        color: #8d8d8d !important;
        position: relative;
        z-index: 9999 !important;
    }

    .img-box-slider.slick-slider .slick-next:before {
        color: #707070!important
    }
    section.desktop {
        display: none;
    }

    .img-slider.crousel_sd.slick-initialized.slick-slider {

        width: 300px !important;

        margin-left: auto;

        margin-right: auto;

    }

    .img-slider .slick-prev:before, .slick-next:before {

        color: #707070 !important;

    }

    button.slick-prev.slick-arrow {

        left: -24px !important;

    }

    .img-box-slider.slick-initialized.slick-slider {

        width: 300px !important;

        margin-left: auto;

        margin-right: auto;

    }

    .slick-next {

        right: -24px !important;

    }

    .col-lg-5.col-sm-5.for_teacher {

        flex: 0 0 45.666667% !important;

        max-width: 48.666667% !important;

        padding-left: 8px;

    }

    .col-lg-5.col-sm-5.for_people {

        flex: 0 0 45.666667% !important;

        max-width: 47.666667% !important;

    }

    a.for-btn {

        font-size: 14px;

        line-height: 35px;

    }

    .center-boredr {

        height: 450px;

        margin-left: 1px;

    }

    .col-lg-4.col-sm-4.contact_top {

        flex: 0 0 49.333333%;

        max-width: 49.333333%;

    }

    .col-lg-3.col-sm-3.top_icon {

        flex: 0 0 49.333333%;

        max-width: 49.333333%;

    }

    .col-lg-4.col-sm-4.contact_top h1 {

        text-align: left;

    }

    .icons li {

        width: 25.42px;

        text-align: center;

        height: 24px;

        margin-right: 15px;

        padding-top: 0px;

    }
    .foter-top a.icons-link {
        text-align: center;
        background: #000;
        height: 48px;
        margin-right: -2px;
        border-radius: 50px;
        /* padding: 12px 14px; */
        width: 45px !important;
        display: inline-block;
        font-size: 19px;
    }

    .icons-link i {
        color: #FFFFFF;
        font-size: 22px;
    }

    .header-btn {

        padding-top: 0px;
        padding-left: 0px;
        text-align: right;

    }

    .header-title h1 {

        font-size: 16px;

        line-height: 10px;

    }

    a.sign-login-btn {

        font-size: 12px;

    }

    .col-lg-4.col-sm-3.logo {

        flex: 0 0 50.333333%;

        max-width: 50.333333%;

    }

    .hader .logo-img img {

        width: 70%;

    }

    .heading-wrapper h2 {
        color: #0B1320;
        font-size: 30px !important;
        padding-bottom: 14px;
    }

    .heading-wrapper p {

        font-size: 14px;

    }

    .col-lg-5.col-sm-5.for_people img {

        height: 90px;

    }

    .col-lg-5.col-sm-5.for_teacher img {

        height: 90px;

    }

    ul.nav-menu {

        padding: 0px 28px 0px 0px;

        display: block;

        margin: 0;

    }

    .col-lg-8.col-sm-9.menu_icon {

        flex: 0 0 49.333333%;

        max-width: 49.333333%;

        padding-top: 2%;

        text-align: right;

    }

    .col-lg-8.col-sm-9.menu_icon a {

        color: #020202;

    }

    ul.nav-menu a {

        text-align: left;

    }

    /*header#top {

        background-color: #80808026;

    }*/
    .humber-icon a.icon.mob-nav {
        color: #000;
        font-size: 35px;
    }
    .humber-icon {
        text-align: right;
        padding-right: 33px;
        padding-top: 5px;
        position: relative;
    }
    #click ul.nav-menu {
        padding: 0;
        position: absolute;
        background: #fff;
        z-index: 999;
        right: 0;
        width: 250px;
    }
    #click li.nav-item {
        list-style: none;
        text-align: left;
        display: block;
        padding: 0px 5px !important;
    }
    .header-menu {
        padding-top: 0px;
    }
    .slider-baner button.slick-prev.slick-arrow {
        left: -35px !important;
    }
    .slider-baner button.slick-next.slick-arrow {
        right: -20px !important;
    }

    .top-header {

        padding: 10px 0px;

    }

    header.hader {

        padding: 0px 0px;

    }

    .img-box-slider.slick-initialized.slick-slider {

        height: 500px;

    }

    .center-boredr.border2 {

        height: 640px;

    }

    .icon-box-title {

        height: auto;

    }

    .img-slider.slick-initialized.slick-slider {

        width: 300px !important;

        margin-left: auto;

        margin-right: auto;

    }

    section.header.mobile {

        display: block;

    }

    section.header.main_header {

        display: none;

    }

    /*section.sign-up {

        height: 705px;

    }*/

    section.sign-up.sing2 {

        height: 895px;

    }

    .sign-up .slick-slider {
        margin-left: auto;
        margin-right: auto;
        width: 300px !important;
    }

    .col-lg-6.col-sm-6.sd_imgs {
        flex: 0 0 29%;
        max-width: 29%;
    }

    .col-lg-6.col-sm-6.content_boxs {
        flex: 0 0 70.333333%;
        max-width: 70.333333%;
	}
	
	.center-width {
		width: 100% !important;
	}

    .heading h2 {
        font-size: 28px !important;
        color: #FFFFFF;
        font-weight: bold;
        font-family: 'Montserrat';
        line-height: 37px;
        margin: 0;
        padding-bottom: 10px;
    }
    .heading.heading-desc {

        padding-bottom: 0px;

    }
    .top-baner {
        background-repeat: no-repeat, repeat;
        height: 450px;
       /* display: block;*/
        padding-top: 0px;
    }
    .slider-lady-img img {
        width: 90%;
        position: relative;
        left: 30px;
        /*top: 11px*/;
        z-index: 9999 !important;
	}
	.slider-top-space {
		top: 60px !important;
	}
    .ourvision {
        display: block;
        height: 450px !important;
        padding-top: 80px;
    }

    .tesmonial-title p {
        width: 100%;
        padding-top: 20px;

    }
    .tesmonial-title p.name {
        position: absolute;
        top: 0;
        padding-top: 10px;
    }

    section.about-baner {
        height: 450px;
        display: block;
        justify-content: center;
        align-items: center;
        padding-top: 49px;
        background-position: -40px 0px!important;
    }
    .about-baner {        
        background-position: center;
    }
    section.counting-number {

        padding-bottom: 10px;

    }
    .count-up {
        border: 1px solid #707070;
        padding: 29px 21px 90px;
        margin: 10px;
    }

    .counter-count {
        font-size: 55px !important;
        width: 50%;
        float: left;
        padding-top: 23px;
    }

    .count-up h3 {
        width: 50%;
        float: right;
        padding: 0;
        text-align: left;
    }
    .journey {
        padding-bottom: 0;
    }
    .Subscription-content h3 {
        font-size: 20px !important;
    }

    .oversion-content {

        width: 100%;

    }
    .Subscription-box {
        margin-bottom: 8%;
    }
    .Subscription-content h3 {
        color: #0B1320;    font-size: 30px;
    }
    .Subscription-baner {        
        height: 360px !important;
    }
    .Subscription-content p {
        font-size: 17px;
    }
    .Subscription-btn a {
        font-size: 25px;
    }
    .Subs-sec {
        padding: 10px 0px 55px!important;
    }
    .contact-baner {        
        height: 264px !important;
    }

    section.about-baner.faq.top-baner {
        height: 280px;
    }
    input#search {
        font-size: 19px;
        padding: 15px;
        height: 52px;
    }
    a.card-title {
        padding-left: 30px;
        width: 90%;
        font-size: 16px;
        line-height: 24px;
    }
    .contact-icons i {
        color: #0B1320;
        font-size: 25px;
    }
    span.contact-title {
        font-size: 30px;
    }
    .country-content {
        padding-left: 45px;
        padding-bottom: 2px;
    }
    .country-content h4 {
        color: #0B1320;
        font-size: 19px;
    }
    .country-content p {
        color: #040404;
        font-size: 16px;
        width: 100%;
    }
    .contact-form {
        width: 100%;
        padding: 30px 15px;
    }
    .contact-form h5 {
        font-size: 30px;
    }
    .form-control {
        font-size: 17px;
        height: 45px;
    }
    .icon-content {
        padding-bottom: 1px;
    }
    button.btn.sumbit-btn {
        font-size: 18px;
    }
    .mail a {
        font-size: 22px;
    }
    .country-content {
        padding-left: 45px;
        padding-bottom: 2px;
    }
    .contact-content {
        padding-bottom: 10px;
    }
    .copy-right p {
        text-align: center;
    }
    .foter-top .header-icon {
        text-align: center;
        padding-top: 20px;
        width: 70%;
        margin: 0 auto;
    }
   .slider-baner button.slick-next.slick-arrow {
        right: 6px !important;
    }
   .slider-baner button.slick-prev.slick-arrow {
        left: -9px !important;
        z-index: 999;
    }
    .img_crousel .slick-arrow:before {
        color: #707070 !important;
    }
    .why-teacher button.slick-prev.slick-arrow {
        left: 2px !important;
    }
    span.icons {
        padding-right: 5px;
    }
    span.heading-text {
        font-size: 22px;
    }
    .heading-title {
        padding-top: 0;
    }
    .make-content {
        width: 100%;
        padding: 20px;
    }
    .sign-up .slick-slider-sign.slick-slide.slick-cloned {
        width: 300px!important;
    }
    .top-img {
        width: 100%;
        float: unset;
    }
    .sign-up .content-title.heading-desc {
        width: 100%;
        float: unset;
        text-align: center;
    }
    .top-img {
        text-align: center;
        padding-bottom: 0;
    }
    .number {
        font-size: 35px;
    }
    p.bg-title {
        width: 51%;
        left: 53px;
        bottom: -20px;
    }
    .container-width {
        width: 370px;
    }
    .sliderbaner-title {
        width: 100%;
         padding: 0;
    }
    .slider-lady-img {
        width: 100%;
    }

}

@media only screen and (min-device-width: 376px) and (max-device-width: 414px){
    .img-5 img {
        width: 100% !important;
        position: unset;
        top: 0;
        left: auto !important;
        height: auto !important;
    }
    .container-width {
        width: 370px;
    }
    .sliderbaner-title {
        width: 100%;
        padding: 0;
    }
    .slider-lady-img {
        width: 100%;
    }
    /*01-02-2022*/
    .sign-up .slick-slider-sign.slick-slide.slick-cloned {
        width: 300px!important;
    }
    span.icons {
        padding-right: 5px;
    }
     span.heading-text {
        font-size: 22px;
    }
    .heading-title {
        padding-top: 0;
    }
    .make-content {
        width: 100%;
        padding: 20px;
    }
    .lorem-img img {
        width: 100%;
        height: auto;
    }
    .lorem-sec .heading.heading-desc {
        padding-bottom: 42px;
    }
    .botem-img img {
        width: 100%;
    }
    .top-img {
        width: 100%;
        float: unset;
    }
    .sign-up .content-title.heading-desc {
        width: 100%;
        float: unset;
        text-align: center;
    }
    .top-img {
        text-align: center;
        padding-bottom: 0;
    }
     .number {
        font-size: 35px;
    }
    .text-title {
        font-size: 29px;
    }
        p.bg-title {
        width: 80%;
        left: 40px;
        bottom: -20px;
    }

   /*end*/


	p {
	    font-size: 16px !important;
	    line-height: 30px;
	}
	h4 {
	    font-size: 30px !important;
	}
	h3 {
	    font-size: 30px !important;
	}
	.img_crousel .slick-arrow:before {
	    color: #787878 !important;
	}
	.sign-up .slick-arrow {
	    top: 53% !important;
	}
	.sliderbaner-title {
	    padding-left: 1px !important;
	}
	footer.foter-top.desktop {
	    display: none;
	}
	footer.foter-top.mobile {
	    display: block;
	}
	ul.slick-dots {
	    position: relative;
	}
	.slick-slider-sign {
	    padding: 0px 0px;
	}
	.slick-slider-sign.slick-slide.slick-current.slick-active {
	    border-right: 0;
	}
	.why-teacher .slick-arrow {
	    display: block !important;
	}
	.tesmonial-title h4 {
	    position: absolute;
	    top: -166px;
	    font-size: 23px !important;
	}
	.foter-content {
	    text-align: center;
	}
	.foter-top .logo-img {
	    text-align: center;
	}
	.foter-menu ul.nav-menu {
	    padding: 0 !important;
	}
	.foter-menu a.nav-link {
	    padding: 0;
	    font-size: 15px;
	}
	.foter-content {
	    padding: 2px;
	}
	.icon-title-content {
        padding: 0;
        float: unset;
    }
	ul.icons {
	    padding-left: 111px;
	    display: flex;
	    margin: 0;
	}
	.img_crousel .slick-arrow:before {
	    color: #707070 !important;
	}
	.slick-slider-sign.slick-slide.slick-cloned {
	    width: 300px !important;
	}
	.foter-top ul.icons {
        padding: 16px;
        display: flex;
        margin: 0;
    }
	.heading h2 {
	    font-size: 20px !important;
	    color: #FFFFFF;
	    font-weight: bold;
	    line-height: 21px;
	    margin: 0;
	    padding-bottom: 25px;
	}
	.for_people {
	    padding: 0;
	    border: 0;
	}
	.sign-up .slick-next {
	    right: -11px !important;
	}
	.content-title {
	    text-align: center;
	    padding: 13px;
	}
	button.slick-prev.slick-arrow:before {
	    padding: 5px 10px;
	    font-size: 17px !important;
	}
	button.slick-next.slick-arrow:before {
	    padding: 5px 11px;
	    font-size: 17px !important;
	}
	section.mobile {
	    display: block;
	}
	.header-btn {
	    text-align: right;
	}
	.tesmonial-img h4 {
	    font-size: 30px;
	    font-weight: bold;
	}
	section.testmonila {
	    padding-bottom: 8px;
	}
	.img_crousel .logo-slider {
	    width: 100px !important;
	}
	.img-slider img {
	    width: 84px;
	    height: 124px;
	    object-fit: contain;
	}
	.img-slider button.slick-prev.slick-arrow {
	    left: -35px !important;
	}
	.img-slider button.slick-next.slick-arrow {
	    top: 43% !important;
	}
	#why-teacher .slick-arrow {
	    top: 21% !important;
	}
	#why-teacher ul.slick-dots {
	    display: none !important;
	}
	.teacher-icon-box {
	    width: auto;
	    margin: 0 auto;
	}
	.teacher-icon-box {
	    text-align: center;
	    padding-bottom: 0px;
	}
	#why-teacher .slick-arrow:before {
	    color: #707070 !important;
	    border: 3px solid #707070 !important;
	}
	#why-teacher button.slick-next {
        right: 22px !important;
    }
	.sign-up-mobile {
	    height: auto !important;
	    padding-bottom: 132px !important;
	}
	.count-up {
	    border: 1px solid #707070;
	    padding: 29px 21px 90px;
	    margin: 10px;
	}
	.country-content h4 {
	    font-family: unset;
	    font-size: 16px !important;
	    padding-bottom: 10px;
	}
	.oversion-content {
		text-align: left;
		font-weight: 500;
	}
	.faq {
	    background: #2A316B;
	}
	.foter-top li.nav-item {
	    display: block;
	}
	.foter-menu {
	    padding-bottom: 20px;
	}
	.foter-top .logo-img img {
	    width: 37%;
	    padding-bottom: 30px;
	}
	.header-title.heading-desc {
	    text-align: center;
	    padding-top: 30px;
	}
	footer.foter-top {
	    padding-bottom: 60px;
	}
	.scrol-icon {
	    position: fixed;
	    right: 8px;
	    bottom: 7px;
	}
	.heading p {
	    color: #FFFFFF;
	    font-size: 13px;
	    line-height: 25px;
	    font-family: 'Montserrat';
	}
	.slick-prev {
	    left: 0px !important;
	}
	.sliderbaner-title {
        padding-left: 30px !important;
    }
	.slider-img {
	    text-align: left;
	    position: relative;
	    left: -70px;
	    top: 1px;
	    z-index: 9999;
	}
	.slider-img img {
	    width: 134px;
	}
	.slick-next {
	    right: 155px !important;
	}
	.slick-prev:before, .slick-next:before {
	    font-family: 'slick';
	    font-size: 25px !important;
	}
	.slick-slider .slick-next:before {
	    font-size: 25px !important;
	    color: #fff!important;
	}
	.slick-prev {
	    left: -7px !important;
	}
	.btans {
	    padding: 5px 5px;	    
	    margin-bottom: 6px;
	    font-size: 14px;
	    border-radius: 5px;
	}
	.col-lg-6.col-sm-6.content_box {
	    padding-right: 0px;
	    padding-left: 6%;
	    flex: 0 0 37% !important;
	    max-width: 37% !important;
	}
	.col-lg-6.col-sm-6.sd_img {
	    padding-left: 0px;
	    flex: 0 0 30% !important;
	    max-width: 30% !important;
	}
	.top-baner {
	    background-repeat: no-repeat, repeat;
	    height: 360px;
	}
	section.content-wrapper {
	    padding: 40px 0px;
	}
	.heading-wrapper h2 {
	    color: #0B1320;
	    line-height: 35px;
	    font-size: 30px;
	}
	.heading-wrapper p {
	    padding-bottom: 5px !important;
	    padding-bottom: 30px;
	    font-size: 15px;
	    line-height: 30px;
	}
	a.for-btn {
	    font-size: 14px;
	    width: 190px;
	    line-height: 35px;
	}
	.content-title h3 {
	    color: #040404;
	    font-size: 20px;
	}
	.content-title p {
	    color: #474747;
	    font-size: 13px;
	    font-family: 'Montserrat';
		line-height: 30px;
		font-weight: 500;
	}
	.col-lg-5.col-sm-5.for_people {
	    flex: 0 0 33.666667% !important;
	    max-width: 33.666667% !important;
	}
	.col-lg-2.col-sm-2.border {
	    flex: 0 0 1.666667%;
	    max-width: 1.666667%;
	    padding: 0px;
	}
	.center-boredr {
	    border-left: 1px solid #777;
	    height: 434px;
	    text-align: center;
	    margin-left: 3px;
	    margin-right: 0px;
	}
	.col-lg-5.col-sm-5.for_people img {
	    height: 107px;
	}
	.col-lg-5.col-sm-5.for_teacher img {
	    height: 107px;
	}
	section.sign-up {
	    padding-bottom: 45px;
	}
	.img-box-slider.slick-initialized.slick-slider {
	    height: 441px;
	}
	.img-box-slider .slick-prev:before {
	    font-size: 22px !important;
	    color: #777 !important;
	}
	.img-box-slider.slick-slider .slick-next:before {
	    font-size: 17px !important;
	    color: #7777!important;
	}
	.vedio-title h3 {
	    font-size: 17px;
	    line-height: 30px;
	}
	.overlay {
	    height: 500px;
	}
	.vedio-sec {
	    height: auto;
	}
	.vedio-sec {
	    background-image: url(../images/mobile-middle-baner.png);
	}
	.teacher-title h4 {
	    font-size: 30px;
	    padding-bottom: 5px;
	}
	.teacher-title p {
	    font-size: 16px;
	    padding-bottom: 0px;
	    line-height: 30px;
	}
	.icon-size {
	    width: 116px;
	    height: 112px;
	    padding-top: 30px;
	    font-size: 55px;
	}
	.icon-box-title h4 {
	    font-size: 20px;
	    padding-bottom: 5px;
	}
	.icon-box-title p {
	    font-size: 15px;
	}
	.icon-box-title {
	    height: 138px;
	}
	.icon-box-title.height1 {
	    height: 90px;
	}
	.icon-box-title.height2 {
	    height: 150px;
	}
	.icon-box-title.height3 {
	    height: 116px;
	}
	.icon-box-title.height4 {
	    height: 90px;
	}
	.icon-box-title.height5 {
	    height: 90px;
	}
	section.why-teacher {
	    background: #0B1320;
	    padding-bottom: 40px;
	}
	.tesmonial-img img {
	    width: 38%;
	    height: 116px;
	    object-fit: cover;
	}
	.tesmonial-img {
	    width: 100%;
	    float: unset !important;
	    padding-top: 48px;
	    text-align: center;
	}
	p.name {
	    color: #474747;
	    font-size: 17px;
	}
	.tesmonial-title h4 {
	    font-size: 24px;
	}
	.tesmonial-title p {
	    font-size: 15px;
	}
	.tesmonial-title {
	    padding-top: 20px;
	    width: 100%;
	    float: right;
	    text-align: center;
	    position: relative;
	}
	.tesmonial-title p {
	    width: 65%;
	}
	.slick-dots {
	    width: 100% !important;
	    bottom: 0px !important;
	}
	section.testmonila {
	    padding-top: 30px;
	}
	section.img_crousel {
	    padding: 20px 0px;
	}
	section.img_crousel h2 {
	    font-size: 22px !important;
	    line-height: 30px;
	    padding-bottom: 15px;
	}
	.blog-title h5 {
	    font-size: 24px;
	}
	.blog-title p {
	    font-size: 17px;
	}
	section.blog {
	    padding-bottom: 40px;
	}
	section.global {
        background: #000;
        padding: 50px 20px 0px;
    }
	.teacher-title.heading-desc a {
	    font-size: 15px;
	}
	.slick-slider .slick-next:before {
	    font-size: 25px !important;
	}
	.sign-up .slick-arrow:before {
	    color: #707070 !important;
	}
	.slick-slider .slick-next:before {
	    font-size: 25px !important;
	}
	.img-box-slider .slick-prev:before {
	    color: #8d8d8d !important;
	    position: relative;
	    z-index: 9999 !important;
	}
	.img-box-slider.slick-slider .slick-next:before {
	    color: #707070!important
	}
	section.desktop {
	    display: none;
	}
	.img-slider.crousel_sd.slick-initialized.slick-slider {
	    width: 300px !important;
	    margin-left: auto;
	    margin-right: auto;
	}
	.img-slider .slick-prev:before, .slick-next:before {
	    color: #707070 !important;
	}
	.why-teacher button.slick-prev.slick-arrow {
        left: 4px !important;
        z-index: 999;
    }
	.img-box-slider.slick-initialized.slick-slider {
	    width: 300px !important;
	    margin-left: auto;
	    margin-right: auto;
	}
	.slick-next {
	    right: -24px !important;
	}
	.col-lg-5.col-sm-5.for_teacher {
	    flex: 0 0 45.666667% !important;
	    max-width: 48.666667% !important;
	    padding-left: 8px;
	}
	.col-lg-5.col-sm-5.for_people {
	    flex: 0 0 45.666667% !important;
	    max-width: 47.666667% !important;
	}
	a.for-btn {
	    font-size: 14px;
	    width: 190px;
	    line-height: 35px;
	}
	.center-boredr {
	    height: 450px;
	    margin-left: 1px;
	}
	.col-lg-4.col-sm-4.contact_top {
	    flex: 0 0 49.333333%;
	    max-width: 49.333333%;
	}
	.col-lg-3.col-sm-3.top_icon {
	    flex: 0 0 49.333333%;
	    max-width: 49.333333%;

	}

	.col-lg-4.col-sm-4.contact_top h1 {

	    text-align: left;

	}

	.icons li {

	    width: 25.42px;

	    text-align: center;

	    height: 24px;

	    margin-right: 15px;

	    padding-top: 0px;

	}
	.foter-top a.icons-link {
	    text-align: center;
	    background: #000;
	    height: 48px;
	    margin-right: -2px;
	    border-radius: 50px;
	    /* padding: 12px 14px; */
	    width: 45px !important;
	    display: inline-block;
	    font-size: 19px;
	}

	.icons-link i {
	    color: #FFFFFF;
	    font-size: 22px;
	}

	.header-btn {

	    padding-top: 0px;
	    padding-left: 0px;
	    text-align: right;

	}

	.header-title h1 {

	    font-size: 16px;

	    line-height: 10px;

	}

	a.sign-login-btn {

	    font-size: 12px;

	}

	.col-lg-4.col-sm-3.logo {

	    flex: 0 0 50.333333%;

	    max-width: 50.333333%;

	}

	.hader .logo-img img {

	    width: 70%;

	}

	.heading-wrapper h2 {
	    color: #0B1320;
	    font-size: 30px !important;
	    padding-bottom: 14px;
	}

	.heading-wrapper p {

	    font-size: 14px;

	}

	.col-lg-5.col-sm-5.for_people img {

	    height: 90px;

	}

	.col-lg-5.col-sm-5.for_teacher img {

	    height: 90px;

	}

	ul.nav-menu {

	    padding: 0px 28px 0px 0px;

	    display: block;

	    margin: 0;

	}

	.col-lg-8.col-sm-9.menu_icon {

	    flex: 0 0 49.333333%;

	    max-width: 49.333333%;

	    padding-top: 2%;

	    text-align: right;

	}

	.col-lg-8.col-sm-9.menu_icon a {

	    color: #020202;

	}

	ul.nav-menu a {

	    text-align: left;

	}
	.humber-icon a.icon.mob-nav {
	    color: #000;
	    font-size: 35px;
	}
	.humber-icon {
	    text-align: right;
	    padding-right: 33px;
	    padding-top: 5px;
	    position: relative;
	}
	#click ul.nav-menu {
	    padding: 0;
	    position: absolute;
	    background: #fff;
	    z-index: 999;
	    right: 0;
	    width: 250px;
	}
	#click li.nav-item {
	    list-style: none;
	    text-align: left;
	    display: block;
	    padding: 0px 5px !important;
	}
	.header-menu {
	    padding-top: 0px;
	}
	.slider-baner button.slick-prev.slick-arrow {
	    left: -35px !important;
	}
	.slider-baner button.slick-next.slick-arrow {
	    right: -20px !important;
	}

	.top-header {

	    padding: 10px 0px;

	}

	header.hader {

	    padding: 0px 0px;

	}

	.img-box-slider.slick-initialized.slick-slider {

	    height: 500px;

	}

	.center-boredr.border2 {

	    height: 640px;

	}

	.icon-box-title {

	    height: auto;

	}

	.img-slider.slick-initialized.slick-slider {

	    width: 300px !important;

	    margin-left: auto;

	    margin-right: auto;

	}

	section.header.mobile {

	    display: block;

	}

	section.header.main_header {

	    display: none;

	}

	section.sign-up.sing2 {

	    height: 895px;

	}

    .sign-up .slick-slider {
        margin-left: auto;
        margin-right: auto;
        width: 300px !important;
    }

	.col-lg-6.col-sm-6.sd_imgs {
	    flex: 0 0 29%;
	    max-width: 29%;
	}

	.col-lg-6.col-sm-6.content_boxs {
	    flex: 0 0 70.333333%;
	    max-width: 70.333333%;
	}

	.heading h2 {
	    font-size: 28px !important;
	    color: #FFFFFF;
	    font-weight: bold;
	    font-family: 'Montserrat';
	    line-height: 37px;
	    margin: 0;
	    padding-bottom: 10px;
	}
	.heading.heading-desc {

	    padding-bottom: 0px;

	}
	.top-baner {
	    background-repeat: no-repeat, repeat;
	    height: 450px;
	    /* background-position: 0 0 !important; */
	    display: block;
	    padding-top: 64px;
	}
    .slider-lady-img img {
        width: 100%;
        position: relative;
        left: 0px;
        /*top: 129px;*/
        z-index: 9999 !important;
    }
	.ourvision {	    
	    display: block;
	    height: 450px !important;
	    padding-top: 80px;
	}

	.tesmonial-title p {
	    width: 100%;
	    padding-top: 20px;

	}
	.tesmonial-title p.name {
	    position: absolute;
	    top: 0;
	    padding-top: 10px;
	}

	section.about-baner {
	    height: 450px;
	    display: block;
	    justify-content: center;
	    align-items: center;
	    padding-top: 49px;
	    background-position: -40px 0px!important;
	}
	.about-baner {	   
	    background-position: center;
	}
	section.counting-number {

	    padding-bottom: 10px;

	}
	.count-up {
	    border: 1px solid #707070;
	    padding: 29px 21px 90px;
	    margin: 10px;
	}

	.counter-count {
	    font-size: 55px !important;
	    width: 50%;
	    float: left;
	    padding-top: 23px;
	}

	.count-up h3 {
	    width: 50%;
	    float: right;
	    padding: 0;
	    text-align: left;
	}
	.journey {
	    padding-bottom: 0;
	}
	.Subscription-content h3 {
	    font-size: 20px !important;
	}

	.oversion-content {

	    width: 100%;

	}
	.Subscription-box {
	    margin-bottom: 8%;
	}
	.Subscription-content h3 {
	    color: #0B1320;    font-size: 30px;
	}
	.Subscription-baner {	   
	    height: 360px !important;
	}
	.Subscription-content p {
	    font-size: 17px;
	}
	.Subscription-btn a {
	    font-size: 25px;
	}
	.Subs-sec {
	    padding: 10px 0px 55px!important;
	}
	.contact-baner {	   
	    height: 264px !important;
	}

	section.about-baner.faq.top-baner {
	    height: 280px;
	}
	input#search {
	    font-size: 19px;
	    padding: 15px;
	    height: 52px;
	}
	a.card-title {
	    padding-left: 30px;
	    width: 90%;
	    font-size: 16px;
	    line-height: 24px;
	}
	.contact-icons i {
	    color: #0B1320;
	    font-size: 25px;
	}
	span.contact-title {
	    font-size: 30px;
	}
	.country-content {
	    padding-left: 45px;
	    padding-bottom: 2px;
	}
	.country-content h4 {
	    color: #0B1320;
	    font-size: 19px;
	}
	.country-content p {
	    color: #040404;
	    font-size: 16px;
	    width: 100%;
	}
	.contact-form {
	    width: 100%;
	    padding: 30px 15px;
	}
	.contact-form h5 {
	    font-size: 28px;
	}
	.form-control {
	    font-size: 17px;
	    height: 45px;
	}
	.icon-content {
	    padding-bottom: 1px;
	}
	button.btn.sumbit-btn {
	    font-size: 18px;
	}
	.mail a {
	    font-size: 22px;
	}
	.country-content {
	    padding-left: 45px;
	    padding-bottom: 2px;
	}
	.contact-content {
	    padding-bottom: 10px;
	}
	.copy-right p {
	    text-align: center;
	}
	.foter-top .header-icon {
        text-align: center;
        padding-top: 20px;
        width: 70%;
        margin: 0 auto;
    }
	.slider-baner button.slick-next.slick-arrow {
        right: 6px !important;
    }
	.slider-baner button.slick-prev.slick-arrow {
        left: -8px !important;
        z-index: 999;
    }
	.img_crousel .slick-arrow:before {
	    color: #707070 !important;
	}
    .why-teacher button.slick-prev.slick-arrow {
        left: 4px !important;
    }
    .slider-lady-img img {
        height: 223px !important;
    }
    .slider-baner button.slick-prev.slick-arrow {
        left: 9px !important;
        z-index: 999;
        top: 55% !important;
    }
    .baner-slider .slick-arrow {
        top: 55% !important;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 375px){
    .sliderbaner-title {
        width: 100%;
        float: left;
    }
    .slider-lady-img img {
        height: 223px !important;
    }
     .baner-slider .slick-slider {
        padding-left: 0px !important;
    }
     .icon-title-content {
        padding: 0;
        float: unset;
    }
     .foter-top ul.icons {
        padding: 16px;
        display: flex;
        margin: 0;
    }
    .slider-baner button.slick-prev.slick-arrow {
        left: 9px !important;
        z-index: 999;
        top: 55% !important;
    }
    .baner-slider .slick-arrow {
        top: 55% !important;
    }
	p {
	    font-size: 16px !important;
	    line-height: 30px;
	}
	.baner-slider .slick-slider {
	    padding-left: 23px;
	}
	h4 {
	    font-size: 30px !important;
	}
	h3 {
	    font-size: 30px !important;
	}
	.img_crousel .slick-arrow:before {
	    color: #787878 !important;
	}
	.sliderbaner-title {
        padding-left: 20px !important;
    }
	footer.foter-top.desktop {
	    display: none;
	}
	footer.foter-top.mobile {
	    display: block;
	}
	ul.slick-dots {
	    position: relative;
	}
	.slick-slider-sign {
	    padding: 0px 0px;
	}
	.slick-slider-sign.slick-slide.slick-current.slick-active {
	    border-right: 0;
	}
	.why-teacher .slick-arrow {
	    display: block !important;
	}
	.tesmonial-title h4 {
	    position: absolute;
	    top: -166px;
	    font-size: 23px !important;
	}
	.foter-content {
	    text-align: center;
	}
	.foter-top .logo-img {
	    text-align: center;
	}
	.foter-menu ul.nav-menu {
	    padding: 0 !important;
	}
	.foter-menu a.nav-link {
	    padding: 0;
	    font-size: 15px;
	}
	.foter-content {
	    padding: 2px;
	}
	.icon-title-content {
	    padding-right: 15px;
	    float: unset;
	}
	ul.icons {
	    padding-left: 111px;
	    display: flex;
	    margin: 0;
	}
	.img_crousel .slick-arrow:before {
	    color: #707070 !important;
	}
	.slick-slider-sign.slick-slide.slick-cloned {
	    width: 300px !important;
	}
    .foter-top .header-icon {
        text-align: center;
        padding-top: 20px;
        width: 70%;
        margin: 0 auto;
    }
	.heading h2 {
	    font-size: 20px !important;
	    color: #FFFFFF;
	    font-weight: bold;
	    line-height: 21px;
	    margin: 0;
	    padding-bottom: 25px;
	}
	.for_people {
	    padding: 0;
	    border: 0;
	}
	.sign-up .slick-next {
	    right: 14px !important;
	}
	.content-title {
	    text-align: center;
	    padding: 13px;
	}
	button.slick-prev.slick-arrow:before {
	    padding: 5px 10px;
	    font-size: 17px !important;
	}
	button.slick-next.slick-arrow:before {
	    padding: 5px 11px;
	    font-size: 17px !important;
	}
	section.mobile {
	    display: block;
	}
	.header-btn {
	    text-align: right;
	}
	.tesmonial-img h4 {
	    font-size: 30px;
	    font-weight: bold;
	}
	section.testmonila {
	    padding-bottom: 8px;
	}
	.img_crousel .logo-slider {
	    width: 150px !important;
	    text-align: center;
	}
	.img-slider img {
	    width: 84px;
	    height: 124px;
	    object-fit: contain;
	}
	.img-slider button.slick-prev.slick-arrow {
	    left: -12px !important;
	}
		.img-slider .slick-next {
	    right: 13px !important;
	}
	.img-slider button.slick-next.slick-arrow {
	    top: 43% !important;
	}
	.img_crousel button.slick-next.slick-arrow:before {
	    border: 3px solid #707070 !important;
	    color: #707070 !important;
	}
	#why-teacher .slick-arrow {
	    top: 21% !important;
	}
	#why-teacher ul.slick-dots {
	    display: none !important;
	}
	.teacher-icon-box {
	    width: auto;
	    margin: 0 auto;
	}
	.teacher-icon-box {
	    text-align: center;
	    padding-bottom: 0px;
	}
	#why-teacher .slick-arrow:before {
	    color: #707070 !important;
	    border: 3px solid #707070 !important;
	}
	#why-teacher button.slick-next {
	    right: 12px !important;
	}
	.sign-up .slick-slider-sign {
	    width: 298px !important;
	    object-fit: cover !important;
	}
	.sign-up .top-img img {
	    height: 250px;
	    width: 100% !important;
	}
	.sign-up-mobile {
	    height: auto !important;
	    padding-bottom: 132px !important;
	}
	.count-up {
	    border: 1px solid #707070;
	    padding: 29px 21px 90px;
	    margin: 10px;
	}
	.country-content h4 {
	    font-family: unset;
	    font-size: 16px !important;
	    padding-bottom: 10px;
	}
	.oversion-content {
		text-align: left;
		font-weight: 500;
	}
	.faq {
	    background: #2A316B;
	}
	.foter-top li.nav-item {
	    display: block;
	}
	.foter-menu {
	    padding-bottom: 20px;
	}
	.foter-top .logo-img img {
	    width: 37%;
	    padding-bottom: 30px;
	}
	.header-title.heading-desc {
	    text-align: center;
	    padding-top: 30px;
	}
	footer.foter-top {
	    padding-bottom: 60px;
	}
	.scrol-icon {
	    position: fixed;
	    right: 8px;
	    bottom: 7px;
	}
	.heading p {
	    color: #FFFFFF;
	    font-size: 13px;
	    line-height: 24px;
	    font-family: 'Montserrat';
	}
	.slick-prev {
	    left: 0px !important;
	}
	.sliderbaner-title {
        width: 70%;
        float: left;
        padding: 0px 0px 55px 0px;
    }
    .slider-lady-img {
        width: 30%;
        float: left;
        text-align: left;
        position: relative;
        top: -2px;
    }
	.slider-img {
	    text-align: left;
	    position: relative;
	    left: -70px;
	    top: 1px;
	    z-index: 9999;
	}
	.slider-img img {
	    width: 134px;
	}
	.slick-next {
	    right: 155px !important;
	}
	.slick-prev:before, .slick-next:before {
	    font-family: 'slick';
	    font-size: 25px !important;
	}
	.slick-slider .slick-next:before {
	    font-size: 25px !important;
	    color: #fff!important;
	}
	.slick-prev {
	    left: -7px !important;
	}
	.btans {
	    padding: 5px 5px;	    
	    margin-bottom: 6px;
	    font-size: 14px;
	    border-radius: 5px;
	}
	.col-lg-6.col-sm-6.content_box {
	    padding-right: 0px;
	    padding-left: 6%;
	    flex: 0 0 37% !important;
	    max-width: 37% !important;
	}
	.col-lg-6.col-sm-6.sd_img {
	    padding-left: 0px;
	   flex: 0 0 30% !important;
	    max-width: 30% !important;
	}
	.top-baner {
	    background-repeat: no-repeat, repeat;
	    height: 360px;
	}
	section.content-wrapper {
	    padding: 40px 0px;
	}
	.heading-wrapper h2 {
	    color: #0B1320;
	    line-height: 35px;
	    font-size: 30px;
	}
	.heading-wrapper p {
	    padding-bottom: 5px !important;
	    padding-bottom: 30px;
	    font-size: 15px;
	    line-height: 23px;
	}
	a.for-btn {
	    font-size: 14px;
	    width: 190px;
	    line-height: 35px;
	}
	.content-title h3 {
	    color: #040404;
	    font-size: 20px;
	}
	.content-title p {
	    color: #474747;
	    font-size: 13px;
	    font-family: 'Montserrat';
		line-height: 24px;
		font-weight: 500;
	}
	.col-lg-5.col-sm-5.for_people {
	    flex: 0 0 33.666667% !important;
	    max-width: 33.666667% !important;
	}
	.col-lg-2.col-sm-2.border {
	    flex: 0 0 1.666667%;
	    max-width: 1.666667%;
	    padding: 0px;
	}
	.center-boredr {
	    border-left: 1px solid #777;
	    height: 434px;
	    text-align: center;
	    margin-left: 3px;
	    margin-right: 0px;
	}
	.col-lg-5.col-sm-5.for_people img {
	    height: 107px;
	}
	.col-lg-5.col-sm-5.for_teacher img {
	    height: 107px;
	}
	section.sign-up {
	    padding-bottom: 45px;
	}
	.img-box-slider.slick-initialized.slick-slider {
	    height: 441px;
	}
	.img-box-slider .slick-prev:before {
	    font-size: 22px !important;
	    color: #777 !important;
	}
	.img-box-slider.slick-slider .slick-next:before {
	    font-size: 17px !important;
	    color: #7777!important;
	}
	.vedio-title h3 {
	    font-size: 17px;
	    line-height: 28px;
	}
	.overlay {
	    height: 500px;
	}
	.vedio-sec {
	    height: auto;
	}
	.vedio-sec {
	    background-image: url(../images/mobile-middle-baner.png);
	}
	.teacher-title h4 {
	    font-size: 30px;
	    padding-bottom: 5px;
	}
	.teacher-title p {
	    font-size: 12px;
	    padding-bottom: 0px;
	    line-height: 25px;
	}
	.icon-size {
	    width: 116px;
	    height: 112px;
	    padding-top: 30px;
	    font-size: 55px;
	}
	.icon-box-title h4 {
	    font-size: 20px;
	    padding-bottom: 5px;
	}
	.icon-box-title p {
	    font-size: 15px;
	}
	.icon-box-title {
	    height: 138px;
	}
	.icon-box-title.height1 {
	    height: 90px;
	}
	.icon-box-title.height2 {
	    height: 150px;
	}
	.icon-box-title.height3 {
	    height: 116px;
	}
	.icon-box-title.height4 {
	    height: 90px;
	}
	.icon-box-title.height5 {
	    height: 90px;
	}
	section.why-teacher {
	    background: #0B1320;
	    padding-bottom: 40px;
	}
	.tesmonial-img img {
	    width: 38%;
	    height: 116px;
	    object-fit: cover;
	}
	.tesmonial-img {
	    width: 100%;
	    float: unset !important;
	    padding-top: 48px;
	    text-align: center;
	}
	p.name {
	    color: #474747;
	    font-size: 17px;
	}
	.tesmonial-title h4 {
	    font-size: 24px;
	}
	.tesmonial-title p {
	    font-size: 15px;
	}
	.tesmonial-title {
	    padding-top: 20px;
	    width: 100%;
	    float: right;
	    text-align: center;
	    position: relative;
	}
	.tesmonial-title p {
	    width: 65%;
	}
	.slick-dots {
	    width: 100% !important;
	    bottom: 0px !important;
	}
	section.testmonila {
	    padding-top: 30px;
	}
	section.img_crousel {
	    padding: 20px 0px;
	}
	section.img_crousel h2 {
	    font-size: 22px !important;
	    line-height: 30px;
	    padding-bottom: 15px;
	}
	.blog-title h5 {
	    font-size: 24px;
	}
	.blog-title p {
	    font-size: 17px;
	}
	section.blog {
	    padding-bottom: 40px;
	}
	section.global {
        background: #000;
        padding: 50px 20px 0px;
    }
	.teacher-title.heading-desc a {
	    font-size: 15px;
	}
	.slick-slider .slick-next:before {
	    font-size: 25px !important;
	}
	.sign-up .slick-arrow:before {
	    color: #707070 !important;
	}
	.slick-slider .slick-next:before {
	    font-size: 25px !important;
	}
	.img-box-slider .slick-prev:before {
	    color: #8d8d8d !important;
	    position: relative;
	    z-index: 9999 !important;
	}
	.img-box-slider.slick-slider .slick-next:before {
	    color: #707070!important
	}
	section.desktop {
	    display: none;
	}
	.img-slider.crousel_sd.slick-initialized.slick-slider {
	    width: 300px !important;
	    margin-left: auto;
	    margin-right: auto;
	}
	.img-slider .slick-prev:before, .slick-next:before {
	    color: #707070 !important;
	}
	button.slick-prev.slick-arrow {
	    left: -11px !important;
	}
	.img-box-slider.slick-initialized.slick-slider {
	    width: 300px !important;
	    margin-left: auto;
	    margin-right: auto;
	}
	.slick-next {
	    right: -18px !important;
	}
	.col-lg-5.col-sm-5.for_teacher {
	    flex: 0 0 45.666667% !important;
	    max-width: 48.666667% !important;
	    padding-left: 8px;
	}
	.col-lg-5.col-sm-5.for_people {
	    flex: 0 0 45.666667% !important;
	    max-width: 47.666667% !important;
	}
	a.for-btn {
	    font-size: 14px;
	    width: 190px;
	    line-height: 35px;
	}
	.center-boredr {
	    height: 450px;
	    margin-left: 1px;
	}
	.col-lg-4.col-sm-4.contact_top {
	    flex: 0 0 49.333333%;
	    max-width: 49.333333%;
	}
	.col-lg-3.col-sm-3.top_icon {
	    flex: 0 0 49.333333%;
	    max-width: 49.333333%;
	}
	.col-lg-4.col-sm-4.contact_top h1 {
	    text-align: left;
	}
	.icons li {
	    width: 25.42px;
	    text-align: center;
	    height: 24px;
	    margin-right: 15px;
	    padding-top: 0px;
	}
	.foter-top a.icons-link {
	    text-align: center;
	    background: #000;
	    height: 48px;
	    margin-right: -2px;
	    border-radius: 50px;
	    /* padding: 12px 14px; */
	    width: 45px !important;
	    display: inline-block;
	    font-size: 19px;
	}
	.icons-link i {
	    color: #FFFFFF;
	    font-size: 22px !important;
	}
	.icons-link i {
	    color: #FFFFFF;
	    font-size: 12px;
	}
	.header-btn {
	    padding-top: 0px;
	    padding-left: 0px;
	    text-align: right;
	}
	.header-title h1 {
	    font-size: 16px;
	    line-height: 10px;
	}
	a.sign-login-btn {
	    font-size: 12px;
	}
	.col-lg-4.col-sm-3.logo {
	    flex: 0 0 50.333333%;
	    max-width: 50.333333%;
	}
	.hader .logo-img img {
	    width: 70%;
	}
	.heading-wrapper h2 {
	    color: #0B1320;
	    font-size: 30px !important;
	    padding-bottom: 14px;
	}
	.heading-wrapper p {
	    font-size: 14px;
	}
	.col-lg-5.col-sm-5.for_people img {
	    height: 90px;
	}
	.col-lg-5.col-sm-5.for_teacher img {
	    height: 90px;
	}
	ul.nav-menu {
	    padding: 0px 28px 0px 0px;
	    display: block;
	    margin: 0;
	}
	.col-lg-8.col-sm-9.menu_icon {
	    flex: 0 0 49.333333%;
	    max-width: 49.333333%;
	    padding-top: 2%;
	    text-align: right;
	}
	.col-lg-8.col-sm-9.menu_icon a {
	    color: #020202;
	}
	ul.nav-menu a {
	    text-align: left;
	}
	.humber-icon a.icon.mob-nav {
	    color: #000;
	    font-size: 35px;
	}
	.humber-icon {
	    text-align: right;
	    padding-right: 33px;
	    padding-top: 5px;
	    position: relative;
	}
	#click ul.nav-menu {
	    padding: 0;
	    position: absolute;
	    background: #fff;
	    z-index: 999;
	    right: 0;
	    width: 250px;
	}
	#click li.nav-item {
	    list-style: none;
	    text-align: left;
	    display: block;
	    padding: 0px 5px !important;
	}
	.header-menu {
	    padding-top: 0px;
	}
	.slider-baner button.slick-prev.slick-arrow {
        left: 13px !important;
        z-index: 9999;
    }
	.slider-baner button.slick-next.slick-arrow {
	    right: 22px !important;
	}
	.top-header {
	    padding: 10px 0px;
	}
	header.hader {
	    padding: 0px 0px;
	}

	.img-box-slider.slick-initialized.slick-slider {
	    height: 500px;
	}
	.center-boredr.border2 {
	    height: 640px;
	}
	.icon-box-title {
	    height: auto;
	}
	.img-slider.slick-initialized.slick-slider {
	    width: 300px !important;
	    margin-left: auto;
	    margin-right: auto;
	}
	section.header.mobile {
	    display: block;
	}
	section.header.main_header {
	    display: none;
	}
	section.sign-up.sing2 {
	    height: 940px;
	}
    .sign-up .slick-slider {
        margin-left: auto;
        margin-right: auto;
        width: 300px !important;
    }
	.col-lg-6.col-sm-6.sd_imgs {
	    flex: 0 0 29%;
	    max-width: 29%;
	}
	.col-lg-6.col-sm-6.content_boxs {
	    flex: 0 0 70.333333%;
	    max-width: 70.333333%;
	}
	.heading h2 {
        font-size: 25px !important;
        color: #FFFFFF;
        font-weight: bold;
        font-family: 'Montserrat';
        line-height: 30px;
        margin: 0;
        padding-bottom: 10px;
    }
	.heading.heading-desc {
	    padding-bottom: 0px;
	}
	.top-baner {
	    background-repeat: no-repeat, repeat;
	    height: 450px;
	    display: block;
	    padding-top: 64px;
	}
    .slider-lady-img img {
        width: 80%;
        position: relative;
        left: 10px;
       /* top: 165px;*/
        z-index: 9999 !important;
	}
	.text-title {
		font-size: 28px !important;
	}
	.ourvision {	   
	    display: block;
	    height: 450px !important;
	    padding-top: 80px;
	}
	.tesmonial-title p {
	    width: 100%;
	    padding-top: 20px;

	}
	.tesmonial-title p.name {
	    position: absolute;
	    top: 0;
	    padding-top: 10px;
	}
	section.about-baner {
	    height: 450px;
	    display: block;
	    justify-content: center;
	    align-items: center;
	    padding-top: 49px;
	    background-position: -53px 0px!important;
	}
	.about-baner {	    
	    background-position: center;
	}
	section.counting-number {
	    padding-bottom: 10px;
	}
	.count-up {
	    border: 1px solid #707070;
	    padding: 29px 8px 90px;
	    margin: 10px;
	}
	.counter-count {
	    font-size: 41px !important;
	    width: 35%;
	    float: left;
	    padding-top: 23px;
	}
	.count-up h3 {
	    width: 62%;
	    float: right;
	    padding: 0;
	    text-align: left;
	}
	.journey {
	    padding-bottom: 0;
	}
	.Subscription-content h3 {
	    font-size: 20px !important;
	}
	.oversion-content {
	    width: 100%;
	}
	.Subscription-box {
	    margin-bottom: 8%;
	}
	.Subscription-content h3 {
	    color: #0B1320;    font-size: 30px;
	}
	.Subscription-baner {	    
	    height: 360px !important;
	}
	.Subscription-content p {
	    font-size: 17px;
	}
	.Subscription-btn a {
	    font-size: 18px;
	}
	.Subs-sec {
	    padding: 10px 0px 55px!important;
	}
	.contact-baner {	   
	    height: 264px !important;
	}
	section.about-baner.faq.top-baner {
	    height: 280px;
	}
	input#search {
	    font-size: 19px;
	    padding: 15px;
	    height: 52px;
	}
	a.card-title {
	    padding-left: 30px;
	    width: 90%;
	    font-size: 16px;
	    line-height: 24px;
	}
	.contact-icons i {
	    color: #0B1320;
	    font-size: 25px;
	}
	span.contact-title {
	    font-size: 30px;
	}
	.country-content {
	    padding-left: 45px;
	    padding-bottom: 2px;
	}
	.country-content h4 {
	    color: #0B1320;
	    font-size: 19px;
	}
	.country-content p {
	    color: #040404;
	    font-size: 16px;
	    width: 100%;
	}
	.contact-form {
	    width: 100%;
	    padding: 30px 15px;
	}
	.contact-form h5 {
	    font-size: 24px;
	}
	.form-control {
	    font-size: 17px;
	    height: 45px;
	}
	.icon-content {
	    padding-bottom: 1px;
	}
	button.btn.sumbit-btn {
	    font-size: 18px;
	}
	.mail a {
	    font-size: 22px;
	}
	.country-content {
	    padding-left: 45px;
	    padding-bottom: 2px;
	}
	.contact-content {
	    padding-bottom: 10px;
	}
	.copy-right p {
	    text-align: center;
	}
	.foter-top .header-icon {
	    text-align: center;
	    padding-top: 20px;
	}

    /*02-02-2022*/
     .container-width {
        width: 370px;
    }
    .sliderbaner-title {
        width: 90%;
        padding: 0;
    }
    .slider-lady-img {
        width: 100%;
    }
    .lorem-img img {
        width: 100%;
        height: auto;
    }
    .botem-img img {
        width: 100%;
    }
    .top-img {
        width: 100%;
    }
    .sign-up .content-title.heading-desc {
        width: 100%;
        text-align: center;
    }
    .number {
        font-size: 45px;
    }
    .text-title {
        font-size: 33px;
    }
    p.bg-title {
        width: 80%;
        left: 40px;
        bottom: -21px;
    }
    .sign-up .slick-arrow {
        top: 49% !important;
        z-index: 999;
    }
        .sign-up .slick-slider-sign.slick-slide.slick-cloned {
        width: 300px !important;
    }
     .img-5 img {
        width: 100% !important;
        position: unset;
        top: 0;
        left: auto !important;
        height: auto !important;
    }
    /*end*/
}

@media only screen and (min-device-width: 240px) and (max-device-width: 319px){
    .sliderbaner-title {
        width: 100%;
        float: left;
    }
	p {
	    font-size: 14px !important;
	    line-height: 20px;
	}
	h4 {
	    font-size: 25px !important;
	}
	h3 {
	    font-size: 20px !important;
	}
	.img_crousel .slick-arrow:before {
	    color: #787878 !important;
	}
	.sign-up .slick-arrow {
	    top: 54% !important;
	    z-index: 999;
	}
	.sliderbaner-title {
	    padding-left: 1px !important;
	}
	footer.foter-top.desktop {
	    display: none;
	}
	footer.foter-top.mobile {
	    display: block;
	}
	ul.slick-dots {
	    position: relative;
	}
	.slick-slider-sign {
	    padding: 0px 0px;
	}
	.slick-slider-sign.slick-slide.slick-current.slick-active {
	    border-right: 0;
	}
	.why-teacher .slick-arrow {
	    display: block !important;
	}
	.tesmonial-title h4 {
	    position: absolute;
	    top: -166px;
	    font-size: 23px !important;
	}
	.foter-content {
	    text-align: center;
	}
	.foter-top .logo-img {
	    text-align: center;
	}
	.foter-menu ul.nav-menu {
	    padding: 0 !important;
	}
	.foter-menu a.nav-link {
	    padding: 0;
	    font-size: 14px;
	}
	.foter-menu li.nav-item {
	    padding: 0px 0px !important;
	}
	.icon-title a {
	    font-size: 12px;
	}
	.foter-icons i {
	    font-size: 21px;
	}
	.foter-content {
	    padding: 2px;
	}
	.icon-title-content {
	    padding-right: 15px;
	    float: unset;
	}

	.sliderbaner-title {		
		padding-top: 1px;
	}
	.slide-list li {		
		font-size: 16px !important;		
	}
	.slider-top-space{
		top: 40px !important;
	}
	.slider-lady-img{
		width: 90%;
		height: 90%;
		float: left;
		text-align: center;
		top: 30px;
		padding-bottom: 30px;
		padding-left: 20px;
	}
	.text-title {
		font-size: 16px;
	}
	p.bg-title {
		width: 90%;
		left: 20px;
		bottom: -20px;
	}
	.sign-up .slick-arrow {
        top: 49% !important;
        z-index: 999;
    }
    .sign-up .slick-slider-sign.slick-slide.slick-cloned {
        width: 300px !important;
	}
	.sign-up .content-title.heading-desc {
		width: 100%;
		float: unset;
		text-align: left;
	}
	.top-img {
		width: 100%;
		float: unset;
		padding-bottom: 2px !important;
	}	
    .img-5 img {
        width: 100% !important;
        position: unset;
        top: 0;
        left: auto !important;
        height: auto !important;
    }
	ul.icons {
	    padding-left: 111px;
	    display: flex;
	    margin: 0;
	}
	.img_crousel .slick-arrow:before {
	    color: #707070 !important;
	}
	.sign-up .slick-slider-sign.slick-slide.slick-cloned {
	    width: 213px !important;
	}
	.sign-up .slick-slider {
	    margin-left: auto;
	    margin-right: auto;
	    width: 214px !important;
	}
	.foter-top ul.icons {
	    padding: 27px 0px;
	    /*display: inherit !important;*/
	    margin: 0;
	}
	.botem-img img {
		width: 80% !important;
		height: 80% !important;
	}
	.heading h2 {
	    font-size: 20px !important;
	    color: #FFFFFF;
	    font-weight: bold;
	    line-height: 21px;
	    margin: 0;
	    padding-bottom: 25px;
	}
	.for_people {
	    padding: 0;
	    border: 0;
	}
	.sign-up .slick-next {
	    right: -4px !important;
	}
	.content-title {
	    text-align: center;
	    padding: 13px;
	}
	button.slick-prev.slick-arrow:before {
	    padding: 5px 7px;
	    font-size: 9px !important;
	    z-index: 999;
	}
	button.slick-next.slick-arrow:before {
	    padding: 5px 7px;
	    font-size: 9px !important;
	}
	section.mobile {
	    display: block;
	}
	.header-btn {
	    text-align: right;
	}
	.tesmonial-img h4 {
	    font-size: 30px;
	    font-weight: bold;
	}
	section.testmonila {
	    padding-bottom: 8px;
	}
	.img_crousel .logo-slider {
	    width: 107px !important;
	    text-align: center;
	}
	.img-slider img {
	    width: 56px;
	    height: 124px;
	    object-fit: contain;
	}
	.img-slider button.slick-prev.slick-arrow {
	    left: -12px !important;
	}
		.img-slider .slick-next {
	    right: 1px !important;
	}
	.img-slider button.slick-next.slick-arrow {
	    top: 43% !important;
	}
	.img_crousel button.slick-next.slick-arrow:before {
	    border: 3px solid #707070 !important;
	    color: #707070 !important;
	}
	#why-teacher .slick-arrow {
	    top: 21% !important;
	}
	#why-teacher ul.slick-dots {
	    display: none !important;
	}
	.teacher-icon-box {
	    width: auto;
	    margin: 0 auto;
	}
	.teacher-icon-box {
	    text-align: center;
	    padding-bottom: 0px;
	}
	#why-teacher .slick-arrow:before {
	    color: #707070 !important;
	    border: 3px solid #707070 !important;
	}
	#why-teacher button.slick-next {
	    right: 0px !important;
	}
	.sign-up .top-img img {
	    height: 188px;
	    width: 100% !important;
	    object-fit: contain;
	}
	.sign-up-mobile {
	    height: auto !important;
	    padding-bottom: 132px !important;
	}
	.count-up {
	    border: 1px solid #707070;
	    padding: 29px 21px 90px;
	    margin: 10px;
	}
	.country-content h4 {
	    font-family: unset;
	    font-size: 14px !important;
	    padding-bottom: 10px;
	}
	.oversion-content {
		text-align: left;
		font-weight: 500;
	}
	.faq {
	    background: #2A316B;
	}
	.foter-top li.nav-item {
	    display: block;
	}
	.foter-menu {
	    padding-bottom: 20px;
	}
	.foter-top .logo-img img {
	    width: 37%;
	    padding-bottom: 30px;
	}
	.header-title.heading-desc {
	    text-align: center;
	    padding-top: 30px;
	}
	footer.foter-top {
	    padding-bottom: 60px;
	}
	.scrol-icon {
	    position: fixed;
	    right: 8px;
	    bottom: 7px;
	}
	.heading p {
	    color: #FFFFFF;
	    font-size: 13px;
	    line-height: 25px;
	    font-family: 'Montserrat';
	}
	.why-teacher .slick-slider {
	    margin-left: auto;
	    margin-right: auto;
	    width: 217px !important;
	}
	.why-teacher .slick-slide {
	    width: 217px !important;
	}
	.slick-prev {
	    left: 0px !important;
	}
	.sliderbaner-title {
	    width: 100%;
	}
	.slider-img {
	    text-align: left;
	    position: relative;
	    left: -70px;
	    top: 1px;
	    z-index: 9999;
	}
	.slider-img img {
	    width: 134px;
	}
	.slick-next {
	    right: 155px !important;
	}
	.slick-prev:before, .slick-next:before {
	    font-family: 'slick';
	    font-size: 25px !important;
	}
	.slick-slider .slick-next:before {
	    font-size: 25px !important;
	    color: #fff!important;
	}
	.slick-prev {
	    left: -7px !important;
	}
	.btans {
	    padding: 5px 5px;	    
	    margin-bottom: 6px;
	    font-size: 14px;
	    border-radius: 5px;
	}
	.col-lg-6.col-sm-6.content_box {
	    padding-right: 0px;
	    padding-left: 6%;
	    flex: 0 0 37% !important;
	    max-width: 37% !important;
	}
	.col-lg-6.col-sm-6.sd_img {
	    padding-left: 0px;
	   flex: 0 0 30% !important;
	    max-width: 30% !important;
	}
	.top-baner {
	    background-repeat: no-repeat, repeat;
	    height: 360px;
	}
	section.content-wrapper {
	    padding: 40px 0px !important;
	}
	.heading-wrapper h2 {
	    color: #0B1320;
	    line-height: 35px;
	    font-size: 30px;
	}
	.heading-wrapper p {
	    padding-bottom: 5px !important;
	    padding-bottom: 30px;
	    font-size: 15px;
	    line-height: 23px;
	}
	a.for-btn {
	    font-size: 14px;
	    width: 190px;
	    line-height: 35px;
	}
	.content-title h3 {
	    color: #040404;
	    font-size: 20px;
	}
	.content-title p {
	    color: #474747;
	    font-size: 13px;
	    font-family: 'Montserrat';
	    line-height: 20px;
	}
	.col-lg-5.col-sm-5.for_people {
	    flex: 0 0 33.666667% !important;
	    max-width: 33.666667% !important;
	}
	.col-lg-2.col-sm-2.border {
	    flex: 0 0 1.666667%;
	    max-width: 1.666667%;
	    padding: 0px;
	}
	.center-boredr {
	    border-left: 1px solid #777;
	    height: 434px;
	    text-align: center;
	    margin-left: 3px;
	    margin-right: 0px;
	}
	.col-lg-5.col-sm-5.for_people img {
	    height: 107px;
	}
	.col-lg-5.col-sm-5.for_teacher img {
	    height: 107px;
	}
	section.sign-up {
	    padding-bottom: 45px;
	}
	.img-box-slider.slick-initialized.slick-slider {
	    height: 441px;
	}
	.img-box-slider .slick-prev:before {
	    font-size: 22px !important;
	    color: #777 !important;
	}
	.img-box-slider.slick-slider .slick-next:before {
	    font-size: 17px !important;
	    color: #7777!important;
	}
	.vedio-title h3 {
	    font-size: 17px;
	    line-height: 28px;
	}
	.overlay {
	    height: 500px;
	}
	.vedio-sec {
	    height: auto;
	}
	.vedio-sec {
	    background-image: url(../images/mobile-middle-baner.png);
	}
	/*section.lorem-sec {
	    background-image: url(../images/red-mobile-baner.png);
	    padding: 80px 0px 0px;
	    justify-content: unset;
	    align-items: unset;
	    height: 530px;
	    background-position: 101% 53%;
	}*/
	.teacher-title h4 {
	    font-size: 30px;
	    padding-bottom: 5px;
	}
	.teacher-title p {
	    font-size: 12px;
	    padding-bottom: 0px;
	    line-height: 25px;
	}
	.icon-size {
	    width: 116px;
	    height: 112px;
	    padding-top: 30px;
	    font-size: 55px;
	}
	.icon-box-title h4 {
	    font-size: 20px;
	    padding-bottom: 5px;
	}
	.icon-box-title p {
	    font-size: 15px;
	}
	.icon-box-title {
	    height: 138px;
	}
	.icon-box-title.height1 {
	    height: 90px;
	}
	.icon-box-title.height2 {
	    height: 150px;
	}
	.icon-box-title.height3 {
	    height: 116px;
	}
	.icon-box-title.height4 {
	    height: 90px;
	}
	.icon-box-title.height5 {
	    height: 90px;
	}
	section.why-teacher {
	    background: #0B1320;
	    padding-bottom: 40px;
	}
	.tesmonial-img img {
	    width: 38%;
	    height: 88px;
	    object-fit: cover;
	}
	.tesmonial-img {
	    width: 100%;
	    float: unset !important;
	    padding-top: 100px;
	    text-align: center;
	}
	p.name {
	    color: #474747;
	    font-size: 17px;
	}
	.tesmonial-title h4 {
	    font-size: 24px;
	}
	.tesmonial-title p {
	    font-size: 15px;
	}
	.tesmonial-title {
	    padding-top: 20px;
	    width: 100%;
	    float: right;
	    text-align: center;
	    position: relative;
	}
	.tesmonial-title p {
	    width: 65%;
	}
	.slick-dots {
	    width: 100% !important;
	    bottom: 0px !important;
	}
	section.testmonila {
	    padding-top: 30px;
	}
	section.img_crousel {
	    padding: 20px 0px;
	}
	section.img_crousel h2 {
	    font-size: 22px !important;
	    line-height: 30px;
	    padding-bottom: 15px;
	}
	.blog-title h5 {
	    font-size: 24px;
	}
	.blog-title p {
	    font-size: 17px;
	}
	section.blog {
	    padding-bottom: 40px;
	}
	section.global {
	    background: #000;
	    padding: 50px 20px;
	}
	.teacher-title.heading-desc a {
	    font-size: 14px;
	}
	.slick-slider .slick-next:before {
	    font-size: 25px !important;
	}
	.sign-up .slick-arrow:before {
	    color: #707070 !important;
	}
	.slick-slider .slick-next:before {
	    font-size: 25px !important;
	}
	.img-box-slider .slick-prev:before {
	    color: #8d8d8d !important;
	    position: relative;
	    z-index: 9999 !important;
	}
	.img-box-slider.slick-slider .slick-next:before {
	    color: #707070!important
	}
	section.desktop {
	    display: none;
	}
	.img-slider.crousel_sd.slick-initialized.slick-slider {
	    width: 217px !important;
	    margin-left: auto;
	    margin-right: auto;
	}
	.img-slider .slick-prev:before, .slick-next:before {
	    color: #707070 !important;
	}
	button.slick-prev.slick-arrow {
	    left: -11px !important;
	}
	.img-box-slider.slick-initialized.slick-slider {
	    width: 300px !important;
	    margin-left: auto;
	    margin-right: auto;
	}
	.slick-next {
	    right: -18px !important;
	}
	.col-lg-5.col-sm-5.for_teacher {
	    flex: 0 0 45.666667% !important;
	    max-width: 48.666667% !important;
	    padding-left: 8px;
	}
	.col-lg-5.col-sm-5.for_people {
	    flex: 0 0 45.666667% !important;
	    max-width: 47.666667% !important;
	}
	a.for-btn {
	    font-size: 14px;
	    width: 190px;
	    line-height: 35px;
	}
	.center-boredr {
	    height: 450px;
	    margin-left: 1px;
	}
	.col-lg-4.col-sm-4.contact_top {
	    flex: 0 0 49.333333%;
	    max-width: 49.333333%;
	}
	.col-lg-3.col-sm-3.top_icon {
	    flex: 0 0 49.333333%;
	    max-width: 49.333333%;
	}
	.col-lg-4.col-sm-4.contact_top h1 {
	    text-align: left;
	}
	.icons li {
	    width: 25.42px;
	    text-align: center;
	    height: 24px;
	    margin-right: 15px;
	    padding-top: 0px;
	}
	.foter-top a.icons-link {
	    text-align: center;
	    background: #000;
	    height: 48px;
	    margin-right: -2px;
	    border-radius: 50px;
	    /* padding: 12px 14px; */
	    width: 45px !important;
	    display: inline-block;
	    font-size: 19px;
	}

	.icons-link i {
	    color: #FFFFFF;
	    font-size: 22px !important;
	}
	.icons-link i {
	    color: #FFFFFF;
	    font-size: 12px;
	}
	.header-btn {
	    padding-top: 0px;
	    padding-left: 0px;
	    text-align: right;
	}
	.header-title h1 {
	    font-size: 16px;
	    line-height: 10px;
	}
	a.sign-login-btn {
	    font-size: 12px;
	}
	.col-lg-4.col-sm-3.logo {
	    flex: 0 0 50.333333%;
	    max-width: 50.333333%;
	}
	.hader .logo-img img {
	    width: 70%;
	}
	.heading-wrapper h2 {
	    color: #0B1320;
	    font-size: 22px !important;
	    padding-bottom: 14px;
	    line-height: 30px !important;
	}
	.heading-wrapper p {
	    font-size: 14px;
	}
	.col-lg-5.col-sm-5.for_people img {
	    height: 90px;
	}
	.col-lg-5.col-sm-5.for_teacher img {
	    height: 90px;
	}
	ul.nav-menu {
	   padding: 0px 28px 0px 0px;
	   display: block;
	   margin: 0;
	}
	.col-lg-8.col-sm-9.menu_icon {
	    flex: 0 0 49.333333%;
	    max-width: 49.333333%;
	    padding-top: 2%;
	    text-align: right;
	}
	.col-lg-8.col-sm-9.menu_icon a {
	    color: #020202;
	}
	ul.nav-menu a {
	    text-align: left;
	}
	.humber-icon a.icon.mob-nav {
	    color: #000;
	    font-size: 35px;
	}
	.humber-icon {
	    text-align: right;
	    padding-right: 33px;
	    padding-top: 5px;
	    position: relative;
	}
	#click ul.nav-menu {
	    padding: 0;
	    position: absolute;
	    background: #fff;
	    z-index: 999;
	    right: 0;
	    width: 250px;
	}
	#click li.nav-item {
	    list-style: none;
	    text-align: left;
	    display: block;
	    padding: 0px 5px !important;
	}
	.header-menu {
	    padding-top: 0px;
	}
	.slider-baner button.slick-prev.slick-arrow {
	    left: -12px !important;
	}
	.slider-baner button.slick-next.slick-arrow {
	    right: -1px !important;
	}
	.slider-baner .slick-arrow {
    	right: -148px !important;
	    top: 27% !important;
	}
	.slider-baner button.slick-prev.slick-arrow {
	   top: 27% !important;
	    left: -12px !important;
	}
	.top-header {
	    padding: 10px 0px;
	}
	header.hader {
	    padding: 0px 0px;
	}
	.img-box-slider.slick-initialized.slick-slider {
	    height: 500px;
	}
	.center-boredr.border2 {
	    height: 640px;
	}
	.icon-box-title {
	    height: auto;
	}
	section.header.mobile {
	    display: block;
	}
	section.header.main_header {
	    display: none;
	}
	section.sign-up.sing2 {
	    height: 895px;
	}
	.testmonila .slick-slider {
	    margin-left: auto;
	    margin-right: auto;
	    width: 217px !important;
	}
	.col-lg-6.col-sm-6.sd_imgs {
	    flex: 0 0 29%;
	    max-width: 29%;
	}
	.col-lg-6.col-sm-6.content_boxs {
	    flex: 0 0 70.333333%;
	    max-width: 70.333333%;
	}
	.heading h2 {
	    font-size: 21px !important;
	    color: #FFFFFF;
	    font-weight: bold;
	    font-family: 'Montserrat';
	    line-height: 29px;
	    margin: 0;
	    padding-bottom: 10px;
	}
	.heading.heading-desc {
	    padding-bottom: 0px;
	}
	.top-baner {
	    background-repeat: no-repeat, repeat;
	    height: 450px;
	    /* background-position: 0 0 !important; */
	    display: block;
	    padding-top: 64px;
	}
	/*.slider-baner {
	    background-image: url(../images/Banner3.jpg) !important;
	    background-position: -63px 0px;
	}*/
	.ourvision {	    
	    display: block;
	    height: 450px !important;
	    padding-top: 80px;
	}
	.tesmonial-title p {
	    width: 100%;
	    padding-top: 20px;
	}
	.tesmonial-title p.name {
	    position: absolute;
	    top: 0;
	    padding-top: 10px;
	}
	section.about-baner {
	    height: 450px;
	    display: block;
	    justify-content: center;
	    align-items: center;
	    padding-top: 49px;
	    background-position: -53px 0px!important;
	}
	.about-baner {
	    background-position: center;
	}
	section.counting-number {
	    padding-bottom: 10px;
	}
	.count-up {
	    border: 1px solid #707070;
	    padding: 29px 8px 90px;
	    margin: 10px;
	}
	.counter-count {
	    font-size: 41px !important;
	    width: 35%;
	    float: left;
	    padding-top: 23px;
	}
	.count-up h3 {
	    width: 62%;
	    float: right;
	    padding: 0;
	    text-align: left;
	}
	.journey {
	    padding-bottom: 0;
	}
	.Subscription-content h3 {
	    font-size: 20px !important;
	}
	.oversion-content {
	    width: 100%;
	}
	.Subscription-box {
	    margin-bottom: 8%;
	}
	.Subscription-content h3 {
	    color: #0B1320;    font-size: 30px;
	}
	.Subscription-baner {	   
	    height: 360px !important;
	}
	.Subscription-content p {
	    font-size: 17px;
	}
	.Subscription-btn a {
	    font-size: 18px;
	}
	.Subs-sec {
	    padding: 10px 0px 55px!important;
	}
	.contact-baner {	   
	    height: 264px !important;
	}
	section.about-baner.faq.top-baner {
	    height: 280px;
	}
	input#search {
	    font-size: 19px;
	    padding: 15px;
	    height: 52px;
	}
	a.card-title {
	    padding-left: 30px;
	    width: 90%;
	    font-size: 16px;
	    line-height: 24px;
	}
	.contact-icons i {
	    color: #0B1320;
	    font-size: 25px;
	}
	span.contact-title {
	    font-size: 20px;
	}
	.country-content {
	    padding-left: 45px;
	    padding-bottom: 2px;
	}
	.country-content h4 {
	    color: #0B1320;
	    font-size: 19px;
	}
	.country-content p {
	    color: #040404;
	    font-size: 16px;
	    width: 100%;
	}
	.contact-form {
	    width: 100%;
	    padding: 30px 15px;
	}
	.contact-form h5 {
	    font-size: 22px;
	}
	.form-control {
	    font-size: 17px;
	    height: 45px;
	}
	.icon-content {
	    padding-bottom: 1px;
	}
	button.btn.sumbit-btn {
	    font-size: 18px;
	}
	.mail a {
	    font-size: 22px;
	}
	.country-content {
	    padding-left: 45px;
	    padding-bottom: 2px;
	}
	.contact-content {
	    padding-bottom: 10px;
	}
	.copy-right p {
	    text-align: center;
	}
	.foter-top .header-icon {
	    text-align: center;
	    padding-top: 20px;
	}
}


